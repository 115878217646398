import * as React from 'react';
import { Circle, G, Polyline, Svg } from 'react-native-svg';
import { useInheritTextColor } from 'shared/utilities/InheritText';

export default function SuccessIcon() {
  const fill = useInheritTextColor('currentColor');

  return (
    <Svg height="100%" width="100%" viewBox="0 0 24 24">
      <G fill={fill} stroke={fill} strokeLinecap="round" strokeWidth="2">
        <Polyline fill="none" points=" 6,12 10,16 18,8 " />
        <Circle cx="12" cy="12" fill="none" r="11" stroke={fill} />
      </G>
    </Svg>
  );
}
