import { createState, useState } from '@hookstate/core';
import * as React from 'react';
import { useEffect } from 'react';
import { AlertStateViewProps } from 'shared/components/alert/AlertStateCommon';
import AlertStateView from 'shared/components/alert/AlertStateView';

interface AlertState {
  severity: AlertStateViewProps['severity'];
  show: boolean;
  timeout?: NodeJS.Timeout | null;
  message?: string;
}

export const AlertState = createState<AlertState>({
  severity: 'success',
  show: false,
  message: null,
});

export function AlertStateComponent() {
  const state = useState(AlertState);
  const show = state.show.get();
  const message = state.message.get();

  useEffect(() => {
    if (!show) {
      return;
    }

    const previous = AlertState.timeout.get();

    if (previous) {
      clearTimeout(previous);
    }

    const timeout = setTimeout(() => {
      AlertState.merge({
        show: false,
        timeout: null,
      });
    }, 2000);

    AlertState.timeout.set(timeout);
  });

  return <AlertStateView message={message} show={show} severity={state.severity.get()} />;
}
