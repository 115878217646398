import * as React from 'react';
import { G, Polyline, Svg } from 'react-native-svg';
import { useInheritTextColor } from 'shared/utilities/InheritText';

export default function CtrlLeftIcon() {
  const fill = useInheritTextColor('currentColor');

  return (
    <Svg width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke={fill}>
      <G fill={fill} stroke={fill} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="2">
        <Polyline fill="none" points="17,2 7,12 17,22 " stroke={fill} transform="translate(0, 0)" />
      </G>
    </Svg>
  );
}
