import * as React from 'react';
import { InheritTextViewProps, TextContext } from 'shared/utilities/InheritText/InheritTextViewCommon';

export function useInheritTextColorView(fallback: string) {
  return fallback;
}

export default function InheritTextView({ className, children }: InheritTextViewProps) {
  return <TextContext.Provider value={className}>{children}</TextContext.Provider>;
}
