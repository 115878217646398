import * as React from 'react';
import { Circle, G, Line, Svg } from 'react-native-svg';
import { useInheritTextColor } from 'shared/utilities/InheritText';

export default function WarningIcon() {
  const fill = useInheritTextColor('currentColor');

  return (
    <Svg height="100%" width="100%" viewBox="0 0 24 24">
      <G fill={fill} stroke={fill} strokeLinecap="round" strokeWidth="2">
        <Circle cx="12" cy="12" fill="none" r="11" stroke={fill} />
        <Line fill="none" x1="12" x2="12" y1="7" y2="13" />
        <Circle cx="12" cy="17" r="1" stroke="none" />
      </G>
    </Svg>
  );
}
