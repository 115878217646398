export enum PhotogErrorType {
  UNEXPECTED = 'unexpected',
  CARD_ADD_FAILED = 'card_add_failed',
  CARD_AUTHORIZE_FAILED = 'card_authorize_failed',
  CARD_CAPTURE_FAILED = 'card_capture_failed',
  CARD_REFUND_FAILED = 'card_refund_failed',
  OUT_OF_SYNC = 'out_of_sync',
}

export type PhotogErrorMetadataMapping = {
  [PhotogErrorType.CARD_AUTHORIZE_FAILED]: {
    sourceId: string;
    name: string;
    amount: string;
  };
  [PhotogErrorType.CARD_CAPTURE_FAILED]: {
    sourceId: string;
    name: string;
    amount: string;
  };
  [PhotogErrorType.CARD_REFUND_FAILED]: {
    sourceId: string;
    name: string;
    amount: string;
  };
  [PhotogErrorType.CARD_ADD_FAILED]: {
    message: string;
  };
  [PhotogErrorType.OUT_OF_SYNC]: undefined;
  [PhotogErrorType.UNEXPECTED]: undefined;
};

export type PhotogErrorMetadata<T extends PhotogErrorType> = PhotogErrorMetadataMapping[T];

export default class PhotogError<T extends PhotogErrorType> extends Error {
  // allow UI to check if this is a photog error or not
  public photog = true;

  constructor(public type: T, public metadata: PhotogErrorMetadata<T>) {
    super(`Photog Error: ${type}`);
  }

  static isError(error: any): error is PhotogError<PhotogErrorType> {
    return error && !!error['photog'];
  }

  static isType<T extends PhotogErrorType>(error: any, type: T): error is PhotogError<T> {
    return this.isError(error) && error.type === type;
  }
}
