import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { AlertState } from 'shared/components/alert';
import LinkIcon from 'shared/icons/LinkIcon';
import LoadingIcon from 'shared/icons/LoadingIcon';
import { clearUserState, isAuthError } from 'shared/UserState';

export enum LinkStyle {
  BOLD,
  STANDARD,
  SECONDARY,
  QUIET,
}

export interface LinkProps {
  children: React.ReactNode;
  to?: string;
  open?: boolean;
  className?: string;
  onClick?: () => void | Promise<unknown>;
  style?: LinkStyle;
  icon?: React.ReactNode | false;
  iconText?: string;
}

export default function Link({
  to,
  icon,
  iconText,
  style = LinkStyle.STANDARD,
  onClick,
  children,
  open,
  className,
}: LinkProps) {
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const mounted = useRef(true);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  const doClick = async () => {
    const promise = onClick && onClick();

    if (!promise || !promise.then) {
      return;
    }

    promise
      .then((resp) => {
        if (mounted.current) {
          setLoading(false);
        }

        if (resp?.['error']) {
          // urql convention
          AlertState.merge({
            show: true,
            severity: 'error',
          });
        }
      })
      .catch((ex) => {
        if (isAuthError(ex)) {
          clearUserState();

          return;
        }

        if (mounted.current) {
          setLoading(false);
        }

        AlertState.merge({
          show: true,
          severity: 'error',
        });

        // bubble up error
        throw ex;
      });

    if (mounted.current) {
      setLoading(true);
    }
  };

  const classes = ['group-scope inline-flex items-center space-x-2 cursor-pointer', className || ''];

  let nonLoadingIcon = icon || <LinkIcon />;

  if (style === LinkStyle.BOLD) {
    classes.push('text-theme-secondary font-semibold');
  } else if (style === LinkStyle.SECONDARY) {
    classes.push('text-content-secondary');
  } else if (style === LinkStyle.QUIET) {
    nonLoadingIcon = null;
    classes.push('text-content-secondary');
  }

  const loadingIcon = icon === false ? null : loading ? <LoadingIcon /> : nonLoadingIcon;
  const loadingIconText = icon === false || !iconText ? null : loading ? 'Loading' : iconText;

  const content = (
    <>
      <span className="group-scope-hover:opacity-70">{children}</span>
      {loadingIcon && (
        <span className="inline-flex align-middle space-x-1">
          <span className="block icon-xs transition duration-150 transform scale-100 group-scope-hover:scale-125">
            <span className={`block w-4 h-4 ${loadingIconText ? 'text-theme-secondary' : ''}`}>{loadingIcon}</span>
          </span>

          {loadingIconText && <span className="text-xs font-medium text-theme-secondary">{loadingIconText}</span>}
        </span>
      )}
    </>
  );

  if (onClick) {
    return (
      <div className={classes.join(' ')} onClick={doClick}>
        {content}
      </div>
    );
  }

  if (to?.includes('http') || to?.includes('/api') || open) {
    return (
      <a href={to} target="_blank" rel="noreferrer" className={classes.join(' ')}>
        {content}
      </a>
    );
  }

  if (to) {
    return (
      <NavLink to={to || ''} className={classes.join(' ')} onClick={doClick} state={{ backgroundLocation: location }}>
        {content}
      </NavLink>
    );
  }

  return <div className={classes.join(' ')}>{content}</div>;
}
