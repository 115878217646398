import * as React from 'react';
import { G, Path, Svg } from 'react-native-svg';
import { useInheritTextColor } from 'shared/utilities/InheritText';

export default function ErrorIcon() {
  const fill = useInheritTextColor('currentColor');

  return (
    <Svg height="100%" width="100%" viewBox="0 0 24 24">
      <G fill={fill}>
        <Path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
      </G>
    </Svg>
  );
}
