import * as Sentry from '@sentry/react';
import App from 'client/App';
import * as React from 'react';
import { Suspense } from 'react';
import { render } from 'react-dom';
import { IntlProvider } from 'react-intl';
import { UrqlProvider } from 'shared/Graph';
import en from 'shared/translations/en';
import './tailwindcss.pcss';

Sentry.init({ dsn: process.env.SENTRY_DSN });

render(
  <Sentry.ErrorBoundary fallback={<span>An error has occurred, please contact us.</span>}>
    <UrqlProvider url="/graphql">
      <Suspense fallback={<></>}>
        <IntlProvider
          messages={en}
          locale="en"
          defaultLocale="en"
          onError={() => {
            // suppress errors during development
          }}
        >
          <App />
        </IntlProvider>
      </Suspense>
    </UrqlProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);
