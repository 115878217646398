import * as React from 'react';
import { ReactNode } from 'react';
import { PromiseButtonProps } from 'shared/components/button/PromiseButton';
import { ButtonStyle } from 'shared/components/tailwind/Button/ButtonViewCommon';

export enum MessageType {
  SUCCESS,
  ERROR,
  WARNING,
  INFO,
}

export interface MessageProps {
  title?: string;
  type: MessageType;
  children?: React.ReactNode;
  center?: boolean;
  round?: boolean;
  className?: string;
  customActions?: ReactNode;
  actions?: { onClick: PromiseButtonProps['onClick']; label: string; icon?: React.ReactElement; disabled?: boolean }[];
}

export interface MessageViewProps extends Omit<MessageProps, 'type'> {
  titleClass: string;
  textClass: string;
  bgClass: string;
  icon?: React.ReactElement;
  button: ButtonStyle;
}
