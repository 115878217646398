import { useState } from '@hookstate/core';
import { useCallback } from 'react';
import Config from 'shared/Config';
import { LogoutDocument, Permission } from 'shared/generated';
import { GlobalState, UserStateObject, useToken } from 'shared/GlobalState';
import { useMutationPromise } from 'shared/Graph';
import { v4 } from 'uuid';

export function isAuthError(error: Error): boolean {
  return error.message.includes('Forbidden');
}

export function clearUserState(): void {
  GlobalState.merge({
    token: v4(),
    user: {
      user: null,
      attempted: false,
      permissions: [],
    },
  });
}

export function useLogout(redirect?: string | false): () => Promise<void> {
  const token = useToken();
  const logout = useMutationPromise(LogoutDocument);

  return async () => {
    await logout({ token });

    if (redirect !== false) {
      const auth0 = `https://${Config.LOGIN_DOMAIN}/v2/logout?client_id=${Config.LOGIN_CLIENT}`;
      const redir = redirect || `${window.location.protocol}//${window.location.host}`;

      window.location.href = `${auth0}&returnTo=${encodeURIComponent(redir)}`;

      return new Promise<void>(() => {
        // never resolve so we redirect
      });
    }

    clearUserState();
  };
}

export function useUser(): UserStateObject['user'] | null {
  const user = useState(GlobalState.user);

  if (user.user) {
    return user.user.get();
  }

  return null;
}

export interface PermissionsSet {
  (permissions: Permission[]): void;
}

export function useSetPermissions(): PermissionsSet {
  const permissionsState = useState(GlobalState.user.permissions);
  return useCallback((permissions) => permissionsState.set(permissions), [permissionsState]);
}

export interface PermissionCheck {
  (Permission): boolean;
}

export function useHasPermission(): PermissionCheck {
  const userState = useState(GlobalState.user);
  return useCallback(
    (p) => userState.user?.superuser?.get() || new Set(userState.permissions.get() || []).has(p),
    [userState]
  );
}
