import * as React from 'react';
import { useRef } from 'react';
import { ButtonViewProps, styles } from 'shared/components/tailwind/Button/ButtonViewCommon';
import TooltipView from 'shared/components/tailwind/Tooltip/TooltipView';

export default function Button({ icon, onClick, onMouseOut, onMouseOver, ...props }: ButtonViewProps) {
  const classes = styles(props);

  let className = props.className || [];

  if (typeof className === 'string') {
    className = className.split(' ');
  }

  const ref = useRef<HTMLButtonElement>();

  const button = (
    <button
      type="button"
      ref={ref}
      onClick={(e) => {
        onClick && onClick(e);

        try {
          ref.current.blur(); // prevent focus css from staying active
        } catch (ex) {
          // ok if it unmounted
        }
      }}
      className={[...classes.wrapper, ...classes.text, ...className, 'whitespace-nowrap'].join(' ')}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
    >
      {icon && !props.right && <div className={classes.icon.join(' ')}>{icon}</div>}
      {props.children}
      {icon && props.right && <div className={classes.icon.join(' ')}>{icon}</div>}
    </button>
  );

  if (typeof props.disabled === 'string') {
    return <TooltipView text={props.disabled}>{button}</TooltipView>;
  }

  return button;
}
