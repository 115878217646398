import { createState, useState } from '@hookstate/core';
import { parse } from 'query-string';
import { Permission, ProfileQuery } from 'shared/generated';
import { v4 } from 'uuid';

export interface UserStateObject {
  user?: ProfileQuery['profile'];
  permissions: Permission[];
  attempted: boolean;
}

export interface GlobalStateObject {
  user: UserStateObject;
  remember: boolean;
  token: string;
  located?: boolean;
  lat?: number;
  lng?: number;
}

// window not available on mobile
let token = v4();

if (typeof document != 'undefined') {
  // window not available on mobile
  token = parse(window.location.search).token as string;
}

export const GlobalState = createState<GlobalStateObject>({
  user: { attempted: false, permissions: [] },
  remember: false,
  token,
});

export function useToken(): string {
  return useState(GlobalState.token).get();
}

export function useGeo(): () => Promise<void> {
  return () =>
    new Promise<void>((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          GlobalState.merge({
            located: true,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });

          resolve();
        },
        () => {
          GlobalState.merge({
            located: true,
          });
        },
        {
          timeout: 10000,
          enableHighAccuracy: false,
          maximumAge: 60000000,
        }
      );
    });
}

export enum SessionSetting {
  CALENDAR_LAST_DATE = 'calendar_last_date',
}

export enum UserSettings {
  CALENDAR_RESOURCE_WIDTH = 'calendar_resource_width',
}

export const Settings = {
  clear(): void {
    localStorage.clear();
  },

  getItem(key: UserSettings): string | null {
    return localStorage.getItem(key);
  },

  removeItem(key: UserSettings): void {
    localStorage.removeItem(key);
  },

  setItem(key: UserSettings, value: string): void {
    localStorage.setItem(key, value);
  },
};

export const SessionSettings = {
  clear(): void {
    sessionStorage.clear();
  },

  getItem(key: SessionSetting): string | null {
    return sessionStorage.getItem(key);
  },

  removeItem(key: SessionSetting): void {
    sessionStorage.removeItem(key);
  },

  setItem(key: SessionSetting, value: string): void {
    sessionStorage.setItem(key, value);
  },
};
