import * as React from 'react';
export default function LinkIcon() {
  return (
    <svg height="100%" width="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeWidth="2">
        <line fill="none" x1="1" x2="14" y1="12" y2="12" />
        <polyline fill="none" points="10 16 14 12 10 8" />
        <polyline fill="none" points="2 17 2 22 22 22 22 2 2 2 2 7" stroke="currentColor" />
      </g>
    </svg>
  );
}
