import { CombinedError } from '@urql/core';
import * as React from 'react';
import { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';
import { AlertState } from 'shared/components/alert';
import Button from 'shared/components/tailwind/Button';
import { ButtonStyle, ButtonViewProps } from 'shared/components/tailwind/Button/ButtonViewCommon';
import LoadingIcon from 'shared/icons/LoadingIcon';
import { clearUserState, isAuthError } from 'shared/UserState';

export function isVersionError(e: CombinedError): boolean {
  return (e.graphQLErrors || []).some((err) => err.message === 'Version is out of sync.');
}
type ErrorResponse = { error: Error };

export interface PromiseButtonProps extends ButtonViewProps {
  snackbar?: boolean;
  onClick: (e: BaseSyntheticEvent) => Promise<void | boolean | ErrorResponse> | void;
}

export default function PromiseButton({
  onClick,
  snackbar = true,
  disabled,
  icon,
  style = ButtonStyle.PRIMARY,
  ...remaining
}: PromiseButtonProps) {
  const [loading, setLoading] = useState(false);

  const mounted = useRef(true);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  const doClick: ButtonViewProps['onClick'] = async (e) => {
    const promise = onClick(e);

    if (!promise || !promise.then) {
      return;
    }

    promise
      .then((resp) => {
        if (mounted.current) {
          setLoading(false);
        }

        let success = true;

        if (typeof resp !== 'undefined') {
          if (typeof resp === 'boolean') {
            if (!resp) {
              // allow suppression of message
              return;
            }

            success = resp;
          } else if ((resp as ErrorResponse)['error']) {
            success = false;
          }
        }

        if (!success) {
          // urql convention
          AlertState.merge({
            show: true,
            severity: 'error',
          });
        } else if (snackbar) {
          AlertState.merge({
            show: true,
            severity: 'success',
          });
        }
      })
      .catch((ex) => {
        if (isAuthError(ex)) {
          clearUserState();

          return;
        }

        if (mounted.current) {
          setLoading(false);
        }

        if (isVersionError(ex)) {
          AlertState.merge({
            show: true,
            severity: 'error',
            message: 'The content was changed by someone else, please refresh the page.',
          });
        } else {
          AlertState.merge({
            show: true,
            severity: 'error',
          });
        }

        // bubble up error
        throw ex;
      });

    if (mounted.current) {
      setLoading(true);
    }
  };

  if (loading) {
    icon = <LoadingIcon />;
  }

  return <Button icon={icon} onClick={doClick} style={style} disabled={loading || disabled} {...remaining} />;
}
