import Spinner from 'client/global/components/tailwind/Spinner';
import * as React from 'react';

export function SpinnerWrapper() {
  return (
    <div className="flex-1 flex w-full h-full p-6 items-center justify-center">
      <div className="w-32 h-32">
        <Spinner />
      </div>
    </div>
  );
}

let initial = false;

export function loaded() {
  if (initial) {
    return;
  }

  setTimeout(() => {
    // spin logo out of screen, delay prevents render jitter
    document.body.classList.add('loaded');
  }, 100);

  setTimeout(() => {
    // remove dom element so that it does not cover up userback screenshots
    document.querySelector('.wrapper').remove();
  }, 5000);

  initial = true;
}

export default function SpinnerLoader({ children }: { children: React.ReactNode }) {
  if (initial) {
    return <React.Suspense fallback={<SpinnerWrapper />}>{children}</React.Suspense>;
  }

  // allow suspense to bubble up so we can keep showing the photog logo until the app is marked as loaded for the first time
  return <>{children}</>;
}
