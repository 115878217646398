export default {
  GOOGLE_MAP_API: 'AIzaSyDwrjvCZcvbzbVP0Q6cD5whdzeHt2-8rvc',
  STRIPE_TEST: 'pk_test_sZHfOWuwdA3C1iClO6cPCNLn00OnOOiAPM',
  STRIPE_LIVE: 'pk_live_TPaTQeYsMgS0qA7xl1DYNc7400DLRLmyRo',
  CLIENT_DOMAIN: process.env.CLIENT_DOMAIN,
  ALGOLIA_APP: process.env.ALGOLIA_APP,
  APP_URL: process.env.NODE_ENV === 'production' ? 'https://auth.photog.tech' : 'http://localhost:1234',
  LOGIN_DOMAIN: process.env.NODE_ENV === 'production' ? 'auth.photog.tech' : 'photog-development.us.auth0.com',
  LOGIN_CLIENT:
    process.env.NODE_ENV === 'production' ? 'fGUT0N4mfbN9CYWVKV4teB2mspOXY5xt' : 'jmRo4IH9Nynrkd33F6fpvcw8GzQ5pClT',
  SEARCH_INDEX_ORDER:
    process.env.ALGOLIA_INDEX_ORDER || (process.env.NODE_ENV === 'production' ? 'production_order' : 'test_order'),
  SEARCH_INDEX_BUYER:
    process.env.ALGOLIA_INDEX_BUYER || (process.env.NODE_ENV === 'production' ? 'production_buyer' : 'test_buyer'),
  SEARCH_INDEX_GROUP:
    process.env.ALGOLIA_INDEX_GROUP || (process.env.NODE_ENV === 'production' ? 'production_group' : 'test_group'),
};
