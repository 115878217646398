import dayjs from 'dayjs';
import { DayOfWeek, FieldType, OrderSource, TimeZone } from '../enums';
import { isDateWithinMilitaryTime, translateTimezone } from '../util';
import RuleContext, {
  RuleContextAppointment,
  RuleContextBuyer,
  RuleContextField,
  RuleContextOrder,
  RuleContextOrderRequested,
  RuleContextPerformable,
} from './RuleContext';

export enum ConditionLogic {
  AND = 'AND',
  OR = 'OR',
}

export enum ConditionComparator {
  EQUALS = '=',
  NOT_EQUALS = '!=',
  EXISTS = '*',
  NOT_EXISTS = '!*',
  GREATER_THAN = '>',
  GREATER_THAN_EQUALS = '>=',
  LESS_THAN = '<',
  LESS_THAN_EQUALS = '<=',
}

// TODO: graphql fix, ETL Comparator to uppercase values
function convertConditionComparator(value: string): ConditionComparator {
  if (ConditionComparator[value]) {
    return ConditionComparator[value];
  }

  return value as ConditionComparator;
}

export enum ConditionType {
  PERFORMABLE = 'PERFORMABLE',

  BUYER_FIELD = 'BUYER_FIELD',
  BUYER_ADDRESS = 'BUYER_ADDRESS',

  PROVIDER = 'PROVIDER',

  APPOINTMENT_DOW = 'APPOINTMENT_DOW',
  APPOINTMENT_TIME = 'APPOINTMENT_TIME',
  APPOINTMENT_ADDRESS = 'APPOINTMENT_ADDRESS',

  ORDER_REQUESTED_DOW = 'ORDER_REQUESTED_DOW',
  ORDER_REQUESTED_TIME = 'ORDER_REQUESTED_TIME',
  ORDER_SOURCE = 'ORDER_SOURCE',
  ORDER_BUYER = 'ORDER_BUYER',
  ORDER_FIELD = 'ORDER_FIELD',
  ORDER_PERFORMABLE = 'ORDER_PERFORMABLE',
  ORDER_DOW = 'ORDER_DOW',
  ORDER_TIME = 'ORDER_TIME',
}

export interface BaseCondition<T extends ConditionType, M> {
  id: string;
  type: T;
  logic: ConditionLogic;
  metadata: M;
  group: number[];
}

export interface ConditionBuyer {
  buyerId: string[];
  comparator: ConditionComparator.EQUALS | ConditionComparator.NOT_EQUALS;
}

export type BaseConditionField<T extends FieldType, M> = {
  type: T;
} & M;

export interface ConditionFieldSelect {
  valueId?: string;
  comparator: ConditionComparator.EQUALS | ConditionComparator.NOT_EQUALS;
}

export interface ConditionFieldDate {
  dow?: ConditionDayOfWeek;
  time?: ConditionTime;
  comparator: ConditionComparator.EQUALS | ConditionComparator.NOT_EQUALS;
}

export type ConditionFieldDynamic =
  | BaseConditionField<FieldType.DATE, ConditionFieldDate>
  | BaseConditionField<FieldType.SELECT, ConditionFieldSelect>
  | BaseConditionField<FieldType.ADDRESS, ConditionAddress>;

export interface ConditionField {
  fieldId: string;
  existence: ConditionComparator.EXISTS | ConditionComparator.NOT_EXISTS;
  dynamic?: ConditionFieldDynamic;
}

export interface ConditionPerformable {
  performableId: string;
  existence: ConditionComparator.EXISTS | ConditionComparator.NOT_EXISTS;
  field?: ConditionField;
}

export interface ConditionProvider {
  providerMemberId: string;
  comparator: ConditionComparator.EQUALS | ConditionComparator.NOT_EQUALS;
}

export interface ConditionAddress {
  postals: string[];
  comparator: ConditionComparator;
}

export interface ConditionDayOfWeek {
  days: DayOfWeek[];
  holidays: string[];
  windowStart?: number;
  windowStop?: number;
  comparator: ConditionComparator;
}

export interface ConditionTime {
  start: string;
  stop: string;
}

export interface ConditionOrderSource {
  source: OrderSource;
  comparator: ConditionComparator.EQUALS | ConditionComparator.NOT_EQUALS;
}

export type Condition =
  | BaseCondition<ConditionType.PERFORMABLE, ConditionPerformable>
  | BaseCondition<ConditionType.PROVIDER, ConditionProvider>
  | BaseCondition<ConditionType.BUYER_FIELD, ConditionField>
  | BaseCondition<ConditionType.BUYER_ADDRESS, ConditionAddress>
  | BaseCondition<ConditionType.ORDER_REQUESTED_DOW, ConditionDayOfWeek>
  | BaseCondition<ConditionType.ORDER_REQUESTED_TIME, ConditionTime>
  | BaseCondition<ConditionType.APPOINTMENT_DOW, ConditionDayOfWeek>
  | BaseCondition<ConditionType.APPOINTMENT_TIME, ConditionTime>
  | BaseCondition<ConditionType.APPOINTMENT_ADDRESS, ConditionAddress>
  | BaseCondition<ConditionType.ORDER_BUYER, ConditionBuyer>
  | BaseCondition<ConditionType.ORDER_FIELD, ConditionField>
  | BaseCondition<ConditionType.ORDER_PERFORMABLE, ConditionPerformable>
  | BaseCondition<ConditionType.ORDER_DOW, ConditionDayOfWeek>
  | BaseCondition<ConditionType.ORDER_TIME, ConditionTime>
  | BaseCondition<ConditionType.ORDER_SOURCE, ConditionOrderSource>;

function isEqualType(comparator: ConditionComparator) {
  return [ConditionComparator.EQUALS, ConditionComparator.EXISTS].includes(convertConditionComparator(comparator));
}

function isNotEqualType(comparator: ConditionComparator) {
  return [ConditionComparator.NOT_EXISTS, ConditionComparator.NOT_EQUALS].includes(
    convertConditionComparator(comparator)
  );
}

function evaluateDow(metadata: ConditionDayOfWeek, date: Date, holidays: string[], timezone: TimeZone) {
  let match = null as boolean;

  const hasStart = typeof metadata.windowStart === 'number';
  const hasStop = typeof metadata.windowStop === 'number';

  const tz = translateTimezone(timezone);

  if (hasStart || hasStop) {
    if (hasStart) {
      const diff = Math.ceil(dayjs(date).tz(tz).diff(new Date(), 'day', true));

      if (diff < metadata.windowStart) {
        return false;
      }
    }

    if (hasStop) {
      const diff = Math.ceil(dayjs(date).tz(tz).diff(new Date(), 'day', true));

      if (diff > metadata.windowStop) {
        return false;
      }
    }

    return true;
  }

  if (metadata.days.length > 0) {
    const day = dayjs(date).tz(tz).format('dddd').toLowerCase();

    // TODO: ETL days to be uppercase
    match = metadata.days.map((d) => d.toLowerCase()).includes(day as DayOfWeek);
  }

  if (metadata.holidays.length > 0) {
    match = metadata.holidays.some((h) => holidays.includes(h));
  }

  if (match === null) {
    throw new Error('Invalid metadata for ConditionType.APPOINTMENT_DOW: days or holidays required');
  }

  if (convertConditionComparator(metadata.comparator) === ConditionComparator.EQUALS) {
    return match;
  }

  if (convertConditionComparator(metadata.comparator) === ConditionComparator.NOT_EQUALS) {
    return !match;
  }

  throw new Error(
    `Invalid ConditionComparator for ConditionType.APPOINTMENT_DOW: ${convertConditionComparator(metadata.comparator)}`
  );
}

function evaluate(field: RuleContextField, dynamic: ConditionFieldDynamic, timezone: TimeZone) {
  if (dynamic.type === FieldType.SELECT) {
    const valueId = dynamic.valueId;

    if (valueId === null) {
      // TODO: shouldn't write if intent is existence?
      return true;
    }

    const fieldOptionSelected = field.textValue === valueId;

    if (isNotEqualType(dynamic.comparator) && fieldOptionSelected) {
      return false;
    }

    if (isEqualType(dynamic.comparator) && !fieldOptionSelected) {
      return false;
    }
  }

  if (dynamic.type === FieldType.DATE) {
    const date = new Date(field.textValue);

    if (dynamic.dow) {
      return evaluateDow(dynamic.dow, date, [], timezone);
    }

    if (dynamic.time) {
      return isDateWithinMilitaryTime(date, dynamic.time.start, dynamic.time.stop, timezone);
    }
  }

  if (dynamic.type === FieldType.ADDRESS) {
    if (!field.textValue) {
      return false;
    }

    let parsed;

    try {
      parsed = JSON.parse(field.textValue);
    } catch (ex) {
      return false;
    }

    const postals = dynamic.postals || [];

    if (postals.length > 0) {
      switch (convertConditionComparator(dynamic.comparator)) {
        case ConditionComparator.EQUALS:
          return postals.includes(parsed.postalCode);
        case ConditionComparator.NOT_EQUALS:
          return !postals.includes(parsed.postalCode);
      }
    }
  }

  return true;
}

export interface ContextAccessor {
  orderSource(): string | null;

  providerMemberId(): string | null;

  appointmentPostal(): string | null;

  buyerField(): Pick<RuleContextBuyer, 'fields'>[];

  orderPerformable(): Pick<RuleContextOrder, 'performables'> | null;

  orderField(): Pick<RuleContextOrder, 'timezone' | 'fields'> | null;

  orderDow(): Pick<RuleContextOrder, 'date' | 'holidays'> | null;

  orderTime(): Pick<RuleContextOrder, 'date' | 'timezone'> | null;

  orderRequestedTime(): Pick<RuleContextOrderRequested, 'start' | 'timezone'>[];

  orderRequestedDow(): Pick<RuleContextOrderRequested, 'start' | 'holidays' | 'timezone'>[];

  appointmentTime(): Pick<RuleContextAppointment, 'start' | 'timezone'> | null;

  appointmentDow(): Pick<RuleContextAppointment, 'start' | 'holidays' | 'timezone'> | null;

  orderBuyer(): string[];

  performable(): { performable: Pick<RuleContextPerformable, 'performableId' | 'fields'>; timezone: TimeZone } | null;
}

export class RuleContextAccessor implements ContextAccessor {
  constructor(private context: RuleContext) {}

  orderSource(): OrderSource | null {
    return this.context.order?.source;
  }

  providerMemberId() {
    return this.context.providerMemberId;
  }

  buyerField(): Pick<RuleContextBuyer, 'fields'>[] {
    return this.context.buyers;
  }

  orderPerformable(): Pick<RuleContextOrder, 'performables'> | null {
    return this.context.order;
  }

  appointmentPostal(): string | null {
    return this.context.appointment?.address?.postalCode || this.context.order.address?.postalCode;
  }

  appointmentDow(): Pick<RuleContextAppointment, 'start' | 'holidays' | 'timezone'> | null {
    return this.context.appointment || null;
  }

  appointmentTime(): Pick<RuleContextAppointment, 'start' | 'timezone'> | null {
    return this.context.appointment || null;
  }

  orderRequestedDow(): Pick<RuleContextOrderRequested, 'start' | 'holidays' | 'timezone'>[] {
    return this.context.order.requested || null;
  }

  orderRequestedTime(): Pick<RuleContextOrderRequested, 'start' | 'timezone'>[] {
    return this.context.order.requested || null;
  }

  orderBuyer(): string[] {
    return (this.context.buyers || []).map((b) => b.buyerId);
  }

  orderDow(): Pick<RuleContextOrder, 'date' | 'holidays'> | null {
    return this.context.order;
  }

  orderTime(): Pick<RuleContextOrder, 'date' | 'timezone'> | null {
    return this.context.order;
  }

  orderField(): Pick<RuleContextOrder, 'timezone' | 'fields'> | null {
    return this.context.order;
  }

  performable(): { performable: Pick<RuleContextPerformable, 'performableId' | 'fields'>; timezone: TimeZone } | null {
    return {
      performable: this.context.performable,
      timezone: this.context.order?.timezone || this.context.appointment?.timezone,
    };
  }
}

export function evaluateCondition(accessor: ContextAccessor, condition: Condition): boolean {
  switch (condition.type) {
    case ConditionType.PROVIDER: {
      const match = condition.metadata.providerMemberId === accessor.providerMemberId();

      return convertConditionComparator(condition.metadata.comparator) === ConditionComparator.EQUALS ? match : !match;
    }
    case ConditionType.ORDER_SOURCE: {
      const match = condition.metadata.source === accessor.orderSource();

      return convertConditionComparator(condition.metadata.comparator) === ConditionComparator.EQUALS ? match : !match;
    }
    case ConditionType.ORDER_DOW: {
      const order = accessor.orderDow();

      if (!order) {
        return false;
      }

      let match = null as boolean;

      if (condition.metadata.days.length > 0) {
        const day = dayjs(order.date).format('dddd').toLowerCase();
        match = condition.metadata.days.includes(day as DayOfWeek);
      }

      if (condition.metadata.holidays.length > 0) {
        match = condition.metadata.holidays.some((h) => order.holidays.includes(h));
      }

      if (match === null) {
        throw new Error('Invalid metadata for ConditionType.ORDER_DOW: days or holidays required');
      }

      if (convertConditionComparator(condition.metadata.comparator) === ConditionComparator.EQUALS) {
        return match;
      }

      if (convertConditionComparator(condition.metadata.comparator) === ConditionComparator.NOT_EQUALS) {
        return match;
      }

      throw new Error(
        `Invalid ConditionComparator for ConditionType.APPOINTMENT_DOW: ${convertConditionComparator(
          condition.metadata.comparator
        )}`
      );
    }
    case ConditionType.ORDER_TIME: {
      const order = accessor.orderTime();

      if (!order) {
        return false;
      }

      return isDateWithinMilitaryTime(order.date, condition.metadata.start, condition.metadata.stop, order.timezone);
    }
    case ConditionType.ORDER_REQUESTED_TIME: {
      const requested = accessor.orderRequestedTime();

      for (const request of requested) {
        if (
          isDateWithinMilitaryTime(request.start, condition.metadata.start, condition.metadata.stop, request.timezone)
        ) {
          return true;
        }
      }

      return false;
    }
    case ConditionType.ORDER_REQUESTED_DOW: {
      const requested = accessor.orderRequestedDow();

      for (const request of requested) {
        if (evaluateDow(condition.metadata, request.start, request.holidays, request.timezone)) {
          return true;
        }
      }

      return false;
    }
    case ConditionType.APPOINTMENT_TIME: {
      const appointment = accessor.appointmentTime();

      if (!appointment) {
        return false;
      }

      return isDateWithinMilitaryTime(
        appointment.start,
        condition.metadata.start,
        condition.metadata.stop,
        appointment.timezone
      );
    }
    case ConditionType.APPOINTMENT_DOW: {
      const appointment = accessor.appointmentDow();

      if (!appointment) {
        return false;
      }

      return evaluateDow(condition.metadata, appointment.start, appointment.holidays, appointment.timezone);
    }
    case ConditionType.ORDER_BUYER: {
      const found = accessor.orderBuyer().some((b) => condition.metadata.buyerId.includes(b));

      if (condition.metadata.comparator === ConditionComparator.NOT_EQUALS) {
        return !found;
      }

      return found;
    }
    case ConditionType.PERFORMABLE: {
      const { performable, timezone } = accessor.performable();

      const isPerformableMatch = performable?.performableId === condition.metadata.performableId;

      if (condition.metadata.field) {
        if (!isPerformableMatch) {
          return false;
        }

        const field = performable.fields.find((f) => f.fieldId === condition.metadata.field.fieldId);

        const fieldHasValue = field && (field.booleanValue || !!field.textValue || !!field.numberValue);

        if (isNotEqualType(condition.metadata.field.existence) && fieldHasValue) {
          return false;
        }

        if (isEqualType(condition.metadata.field.existence) && !fieldHasValue) {
          return false;
        }

        if (!timezone) {
          throw new Error('need a timezone');
        }

        if (condition.metadata.field.dynamic) {
          return evaluate(field, condition.metadata.field.dynamic, timezone);
        }

        return true;
      } else {
        if (isNotEqualType(condition.metadata.existence) && isPerformableMatch) {
          return false;
        }

        if (isEqualType(condition.metadata.existence) && !isPerformableMatch) {
          return false;
        }
      }

      return true;
    }
    case ConditionType.ORDER_FIELD: {
      const order = accessor.orderField();

      if (!order) {
        return false;
      }

      const field = order.fields.find((f) => f.fieldId === condition.metadata.fieldId);

      if (isNotEqualType(condition.metadata.existence) && field) {
        return false;
      }

      if (
        isEqualType(condition.metadata.existence) &&
        (!field || !(field.booleanValue || !!field.textValue || !!field.numberValue))
      ) {
        return false;
      }

      if (condition.metadata.dynamic) {
        return evaluate(field, condition.metadata.dynamic, order.timezone);
      }

      return true;
    }
    case ConditionType.BUYER_FIELD: {
      const buyers = accessor.buyerField();
      const matches = buyers.filter((b) =>
        b.fields.some((f) => {
          if (f.fieldId !== condition.metadata.fieldId) {
            return false;
          }

          if (f.type === FieldType.BOOLEAN) {
            return f.booleanValue;
          }

          return true;
        })
      );

      if (isNotEqualType(condition.metadata.existence) && matches.length) {
        return false;
      }

      if (isEqualType(condition.metadata.existence) && !matches.length) {
        return false;
      }

      if (condition.metadata.dynamic?.type === FieldType.SELECT) {
        const valueId = condition.metadata.dynamic.valueId;

        const fieldOptionSelected = matches.some((b) => b.fields.some((f) => f.textValue === valueId));

        if (isNotEqualType(condition.metadata.dynamic.comparator) && fieldOptionSelected) {
          return false;
        }

        if (isEqualType(condition.metadata.dynamic.comparator) && !fieldOptionSelected) {
          return false;
        }
      }

      return true;
    }
    case ConditionType.ORDER_PERFORMABLE: {
      const order = accessor.orderPerformable();

      if (!order) {
        return false;
      }

      const performables = order.performables.filter(
        (performable) => condition.metadata.performableId === performable.performableId
      );

      if (isNotEqualType(condition.metadata.existence) && performables.length) {
        return false;
      }

      if (isEqualType(condition.metadata.existence) && !performables.length) {
        return false;
      }

      if (condition.metadata.field) {
        const hasValue = performables.some((p) =>
          p.fields.some(
            (field) =>
              field.fieldId === condition.metadata.field.fieldId &&
              (field.booleanValue || !!field.textValue || !!field.numberValue)
          )
        );

        if (
          convertConditionComparator(condition.metadata.field.existence) === ConditionComparator.EXISTS &&
          !hasValue
        ) {
          return false;
        }

        if (
          convertConditionComparator(condition.metadata.field.existence) === ConditionComparator.NOT_EXISTS &&
          hasValue
        ) {
          return false;
        }

        if (condition.metadata.field.dynamic) {
          if (condition.metadata.field.dynamic.type === FieldType.SELECT) {
            const valueId = condition.metadata.field.dynamic.valueId;

            if (valueId) {
              const fieldOptionSelected = performables.some((p) => p.fields.some((f) => f.textValue === valueId));

              if (isNotEqualType(condition.metadata.field.dynamic.comparator) && fieldOptionSelected) {
                return false;
              }

              if (isEqualType(condition.metadata.field.dynamic.comparator) && !fieldOptionSelected) {
                return false;
              }
            }
          }
        }
      }

      return true;
    }
    case ConditionType.APPOINTMENT_ADDRESS: {
      const postalCode = accessor.appointmentPostal();

      if (!postalCode) {
        return false;
      }

      if (!condition.metadata.postals.length) {
        throw new Error('Missing postal codes for ConditionType.BUYER_ADDRESS');
      }

      switch (convertConditionComparator(condition.metadata.comparator)) {
        case ConditionComparator.EQUALS:
          return condition.metadata.postals.includes(postalCode);
        case ConditionComparator.NOT_EQUALS:
          return !condition.metadata.postals.includes(postalCode);
      }

      throw new Error(
        `Invalid ConditionComparator for ConditionType.APPOINTMENT_ADDRESS: ${convertConditionComparator(
          condition.metadata.comparator
        )}`
      );
    }
  }
}
