import * as React from 'react';
export default function Spinner({ transparent = false }: { transparent?: boolean }) {
  if (transparent) {
    return (
      <div className="relative w-full h-full opacity-50">
        <div className="animate-spin ease-linear rounded-full border-t-8 border-gray-800 h-full w-full absolute"></div>
        <div className="rounded-full border-8 border-t-8 border-gray-700 h-full w-full"></div>
      </div>
    );
  }

  return (
    <div className="relative w-full h-full">
      <div className="animate-spin ease-linear rounded-full border-t-8 border-gray-300 h-full w-full absolute"></div>
      <div className="rounded-full border-8 border-t-8 border-gray-200 h-full w-full"></div>
    </div>
  );
}
