import { State } from '@hookstate/core';
import * as React from 'react';
import { BaseSyntheticEvent, MouseEventHandler } from 'react';

export enum ButtonStyle {
  // solid
  PRIMARY = 'primary',
  INVERSE = 'inverse',
  DANGER = 'danger',

  // input
  INPUT = 'input',

  // bordered
  SECONDARY = 'secondary',
  CONTENT = 'content',
  TERTIARY = 'tertiary',

  // used with <Message />
  SUCCESS = 'green',
  ERROR = 'red',
  WARNING = 'yellow',
  INFO = 'blue',

  QUIET = 'quiet',
}

export interface ButtonViewProps {
  icon?: React.ReactElement;
  children?: string | string[];
  onMouseOver?: MouseEventHandler<unknown>;
  onMouseOut?: MouseEventHandler<unknown>;
  style?: ButtonStyle;
  right?: boolean;
  large?: boolean;
  slim?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disabled?: boolean | string | State<any>;
  onClick?: (e: BaseSyntheticEvent) => void;
  className?: string | string[];
}

type StylesProps = Pick<ButtonViewProps, 'children' | 'right' | 'large' | 'slim' | 'disabled' | 'style'>;

export function styles({ large, slim, disabled, style, children, right }: StylesProps): {
  wrapper: string[];
  text: string[];
  icon: string[];
} {
  let wrapper: string[] = [
    'flex-row',
    'inline-flex',
    'justify-center',
    'items-center',
    'ring-2',
    'ring-opacity-100',
    'ring-transparent',
    'transform',
    'transition',
    'duration-150',
    'rounded-md',
    'focus:outline-none',
  ];

  let text = [large ? 'text-base' : slim ? 'text-xs' : 'text-sm', 'font-medium'];

  if (disabled) {
    wrapper.push('cursor-not-allowed');
  }

  if (style && [ButtonStyle.ERROR, ButtonStyle.WARNING, ButtonStyle.SUCCESS, ButtonStyle.INFO].includes(style)) {
    wrapper = [
      ...wrapper,
      'px-4 py-2',
      'ml-2',
      `bg-${style}-200`,
      `hover:bg-${style}-300`,
      'focus:ring-2',
      'focus:ring-offset-2',
      `focus:ring-offset-${style}-50`,
      `focus:ring-${style}-600`,
    ];

    text.push(`text-${style}-800`);

    if (disabled) {
      wrapper.push('opacity-50');
    }
  } else {
    wrapper = [
      ...wrapper,
      'border',
      'border-transparent',
      slim ? (children ? 'p-2' : '') : large ? 'px-4 py-3' : 'px-4 py-2',
    ];

    if (style === ButtonStyle.SECONDARY) {
      wrapper = [...wrapper, 'scale-100', 'transition duration-300 ease-in-out', 'bg-white'];
      text.push('font-bold');

      if (disabled) {
        text.push('text-gray-400');
      } else {
        wrapper = [...wrapper, 'hover:scale-110', `focus:bg-theme-secondary`, 'border-theme-secondary'];

        text = [...text, `text-theme-secondary`];
      }
    } else if (style === ButtonStyle.TERTIARY) {
      wrapper = [...wrapper, 'scale-100', 'transition duration-300 ease-in-out', 'bg-white'];
      text.push(slim ? 'font-medium' : 'font-semibold');

      if (disabled) {
        text.push('text-gray-400');
      } else {
        wrapper = [...wrapper, 'hover:scale-110', `focus:bg-gray-200`, 'border-gray-300'];

        text = [...text, `text-gray-700`];
      }
    } else if (disabled) {
      if (children && style === ButtonStyle.PRIMARY) {
        wrapper.push('bg-gray-300');
      }

      text.push('text-gray-400');
    } else if (style === ButtonStyle.QUIET) {
      text.push('text-gray-700');
      wrapper.push('scale-100 hover:scale-125 focus:scale-150');
    } else if (style === ButtonStyle.DANGER) {
      text = [...text, 'text-red-700', 'hover:text-red-600'];
      wrapper.push('scale-100 hover:scale-125 focus:scale-150');
    } else if (style === ButtonStyle.INVERSE) {
      wrapper = [...wrapper, 'bg-white', 'hover:bg-theme-primary'];
      text = [...text, 'text-theme-primary', 'hover:text-white'];
    } else {
      text.push('text-white');
      wrapper = [...wrapper, `bg-theme-secondary`, `hover:lighten`];
    }
  }

  const icon = [large ? 'w-6 h-6' : 'w-4 h-4'];

  if (children) {
    icon.push(right ? 'ml-1' : 'mr-2 -ml-1');
  }

  return { wrapper, text, icon };
}
