// https://stackoverflow.com/questions/52443276/how-to-exclude-getter-only-properties-from-type-in-typescript
import dayjs, { extend } from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { FieldType, TimeZone } from './enums';
import { AddressDo } from './model';
import { ConditionLogic } from './rules/Condition';
import { RuleContextField } from './rules/RuleContext';

extend(timezone);
extend(utc);
extend(advanced);

type IfEquals<X, Y, A, B> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B;

type WritableKeysOf<T> = {
  [P in keyof T]: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P, never>;
}[keyof T];
export type WritablePart<T> = Pick<T, WritableKeysOf<T>>;

export function addressFirst(address: Pick<AddressDo, 'line1' | 'line2'>) {
  return address.line1 + (address.line2 != null ? ' ' + address.line2 : '');
}

export function addressSecond(address: Pick<AddressDo, 'city' | 'state' | 'postalCode'>) {
  return `${address.city}, ${address.state} ${address.postalCode}`;
}

export function translateTimezone(timezone: string): string {
  // "US/" prefix not support in node date environments
  switch (timezone) {
    case 'US/Eastern':
      return 'America/New_York';
    case 'US/Central':
      return 'America/Chicago';
    case 'US/Mountain':
      return 'America/Phoenix';
    case 'US/Pacific':
      return 'America/Los_Angeles';
  }

  return timezone;
}

export function convertMilitaryTimeRange(
  date: Date,
  from: string,
  to: string,
  tz: TimeZone
): { begin: Date; end: Date } {
  const timezone = translateTimezone(tz);
  const dateStr = dayjs.tz(date, timezone).format('YYYY-MM-DD');
  const begin = dayjs.tz(dateStr + ' ' + from, timezone).toDate();
  const end = dayjs.tz(dateStr + ' ' + to, timezone).toDate();

  // Get up to the end of the last minute...
  end.setSeconds(59);

  return { begin, end };
}

export function isDateWithinMilitaryTime(date: Date, from: string, to: string, tz: TimeZone) {
  const { begin, end } = convertMilitaryTimeRange(date, from, to, tz);

  const day = dayjs(date);

  const isSameOrAfterBegin = day.isSame(begin) || day.isAfter(begin);
  const isSameOrBeforeEnd = day.isSame(end) || day.isBefore(end);

  return isSameOrAfterBegin && isSameOrBeforeEnd;
}

// TODO: copied from DynamicFieldInput
export function isConditionMatch(
  conditions: { referenceId: string; logic: ConditionLogic; valueId?: string }[],
  state: RuleContextField[],
  fields: { id: string; type: FieldType }[]
) {
  if (!conditions.length) {
    return true;
  }

  let matched = false;

  // only show this field if every condition is satisfied
  for (const condition of conditions) {
    const refField = fields.find((f) => f.id === condition.referenceId);
    const referenceState = state.find((p) => p.fieldId === condition.referenceId);

    if (!referenceState) {
      return false;
    }

    const value = dynamicFieldValueGet(refField.type, referenceState);

    if (condition.logic === ConditionLogic.AND) {
      if (condition.valueId !== null) {
        if (value !== condition.valueId) {
          return false;
        }
      } else if (!value) {
        return false;
      }

      matched = true;
    } else if (condition.logic === ConditionLogic.OR) {
      if (value === condition.valueId) {
        matched = true;
      }
    }
  }

  return matched;
}

// TODO: copied from DynamicFieldInput
export function dynamicFieldValueGet(fieldType: FieldType, state?: RuleContextField): unknown {
  switch (fieldType) {
    case FieldType.BOOLEAN:
      return state?.booleanValue;
    case FieldType.DATE:
    case FieldType.SELECT:
    case FieldType.SINGLE:
    case FieldType.ADDRESS:
    case FieldType.MULTI:
      return state?.textValue;
    case FieldType.NUMBER:
      return state?.numberValue;
    case FieldType.REPEAT:
      return state?.repeatValue;
  }

  return null;
}
