import * as React from 'react';
import { ButtonStyle } from 'shared/components/tailwind/Button/ButtonViewCommon';
import { MessageProps, MessageType } from 'shared/components/tailwind/Message/MessageCommon';
import MessageView from 'shared/components/tailwind/Message/MessageView';
import ErrorIcon from 'shared/icons/ErrorIcon';
import SuccessIcon from 'shared/icons/SuccessIcon';
import WarningIcon from 'shared/icons/WarningIcon';

export default function Message({ type, ...props }: MessageProps) {
  let text = 'text-green-500';
  let titleClass = 'text-green-800';
  let bg = 'bg-green-50';
  let icon = <SuccessIcon />;
  let button = ButtonStyle.SUCCESS;

  if (type === MessageType.ERROR) {
    text = 'text-red-500';
    titleClass = 'text-red-800';
    bg = 'bg-red-50';
    button = ButtonStyle.ERROR;
    icon = <ErrorIcon />;
  } else if (type === MessageType.WARNING) {
    text = 'text-yellow-500';
    titleClass = 'text-yellow-800';
    bg = 'bg-yellow-50';
    button = ButtonStyle.WARNING;
    icon = <WarningIcon />;
  } else if (type === MessageType.INFO) {
    text = 'text-blue-500';
    titleClass = 'text-blue-800';
    bg = 'bg-blue-50';
    button = ButtonStyle.INFO;
    icon = <WarningIcon />;
  }

  return <MessageView {...props} textClass={text} titleClass={titleClass} bgClass={bg} button={button} icon={icon} />;
}
