import * as React from 'react';
import { G, Path, Svg } from 'react-native-svg';
import Spin from 'shared/components/spin';
import { useInheritTextColor } from 'shared/utilities/InheritText';

export default function LoadingIcon() {
  const fill = useInheritTextColor('currentColor');

  return (
    <Spin>
      <Svg height="100%" width="100%" viewBox="0 0 24 24">
        <G fill={fill}>
          <G>
            <Path
              d="M12,24c-2.7734375,0-5.4785156-0.9682617-7.6166992-2.7265625 c-0.4262695-0.3510742-0.487793-0.980957-0.137207-1.4077148c0.3510742-0.4257812,0.9819336-0.4873047,1.4077148-0.137207 C7.4350586,21.1933594,9.6889648,22,12,22c5.5141602,0,10-4.4858398,10-10S17.5141602,2,12,2S2,6.4858398,2,12 c0,1.4248047,0.293457,2.8012695,0.8720703,4.0908203c0.2260742,0.5039062,0.0004883,1.0957031-0.503418,1.3217773 c-0.5053711,0.2246094-1.0957031,0-1.3217773-0.503418C0.3520508,15.3608398,0,13.7089844,0,12C0,5.3833008,5.3833008,0,12,0 s12,5.3833008,12,12S18.6166992,24,12,24z"
              fill={fill}
            />
          </G>
        </G>
      </Svg>
    </Spin>
  );
}
