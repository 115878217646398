import { Transition } from '@headlessui/react';
import { useState } from '@hookstate/core';
import * as React from 'react';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { PopupViewProps } from 'shared/components/tailwind/Popup/PopupViewCommon';

export default function PopupView({
  children,
  activator,
  hover,
  open,
  animate = true,
  rerender,
  className,
}: PopupViewProps) {
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement);

  const state = useState({ popup: false, hovered: false });
  const scopedOpen = useState(open && typeof open !== 'boolean' ? open : false);

  function handleClickOutside(event) {
    if (hover !== undefined) {
      return;
    }

    if (referenceElement?.contains(event.target)) {
      if (open !== undefined) {
        return;
      }

      // user clicked on the activator
      state.popup.set(!state.popup.get());

      return;
    }

    if (popperElement && !popperElement.contains(event.target)) {
      if (open) {
        if (typeof open === 'boolean') {
          return;
        }

        scopedOpen.set(false);

        return;
      }

      // user clicked outside of element that popped up
      state.popup.set(false);
    }
  }

  useEffect(() => {
    update && update();
  }, [rerender, update]);

  useEffect(() => {
    if (open === true) {
      // ignore click events for always open popups
      return;
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const show = (typeof open === 'boolean' ? open : open ? scopedOpen.get() : state.popup.get()) as boolean;

  // React.Suspend fixes positioning issues when popup content is lazy loaded
  const content = (
    <React.Suspense fallback={<></>}>
      <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="pointer-events-auto z-50">
        {children}
      </div>
    </React.Suspense>
  );

  return (
    <>
      <div
        ref={setReferenceElement}
        className={className} // TODO: this is here so we can pass in inline-block for text tooltips, why can't this always be inline-block?
        onMouseLeave={() => {
          if (hover === undefined) {
            return;
          }

          state.merge({
            hovered: false,
            popup: false,
          });
        }}
        onMouseEnter={() => {
          if (hover === undefined) {
            return;
          }

          if (hover === true) {
            state.merge({
              hovered: true,
              popup: true,
            });
          }

          if (typeof hover === 'number') {
            state.hovered.set(true);

            setTimeout(() => {
              if (state.hovered.get()) {
                state.popup.set(true);
              }
            }, hover);
          }
        }}
      >
        {activator}
      </div>

      {createPortal(
        animate ? (
          <Transition
            show={show}
            enter="transition ease-out duration-250"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in duration-250"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="opacity-0"
          >
            {content}
          </Transition>
        ) : show ? (
          content
        ) : (
          <></>
        ),
        document.body
      )}
    </>
  );
}
