import { Transition } from '@headlessui/react';
import * as React from 'react';
import { AlertStateViewProps } from 'shared/components/alert/AlertStateCommon';
import Message from 'shared/components/tailwind/Message';
import { MessageType } from 'shared/components/tailwind/Message/MessageCommon';

export default function AlertStateView({ show, severity, message }: AlertStateViewProps) {
  return (
    <div className="fixed z-50 w-full top-0">
      <Transition
        show={show}
        enter="transition transform ease-out duration-300"
        enterFrom="opacity-0 -translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition transform ease-in duration-300"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0  -translate-y-1"
        className="shadow absolute w-full top-0 border-t border-gray-200"
      >
        {severity === 'success' && (
          <Message type={MessageType.SUCCESS} center round={false}>
            {message ?? 'Successfully saved!'}
          </Message>
        )}
        {severity === 'error' && (
          <Message type={MessageType.ERROR} center round={false}>
            {message ?? 'An unexpected error has occurred.'}
          </Message>
        )}
        {severity === 'info' && (
          <Message type={MessageType.INFO} center round={false}>
            {message ?? 'Successfully added!'}
          </Message>
        )}
      </Transition>
    </div>
  );
}
