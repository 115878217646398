export enum ImportType {
  CUSTOMER = 'CUSTOMER',
}

export enum ImportCustomerSource {
  QUICKBOOKS = 'QUICKBOOKS',
  UPLOAD = 'UPLOAD',
}

export enum DayOfWeek {
  SUNDAY = 'sunday',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
}

export enum JobType {
  TODO = 'TODO',
  STEP = 'STEP',
  ONSITE = 'ONSITE',
  OFFSITE = 'OFFSITE',
}

export enum NotificationMacro {
  RECIPIENT_FIRST = 'recipient_first',
  RECIPIENT_LAST = 'recipient_last',
  INITIATOR_FIRST = 'initiator_first',
  INITIATOR_LAST = 'initiator_last',
  ADDRESS_NUMBER = 'address_number',
  ADDRESS_STREET = 'address_street',
  ADDRESS_CITY = 'address_city',
  ADDRESS_STATE = 'address_state',
  BUYER_COMPANY = 'buyer_company',
  ORDER_ID = 'order_id',
  ORDER_LINK = 'order_link',
  AMOUNT = 'amount',
  JOB_ID = 'job_id',
  JOB_LINK = 'job_link',
  DELIVERABLE_LINK = 'deliverable_link',
  TIME_SHORT = 'time_short',
  TIME_WINDOW = 'time_window',
  DATE_SHORT = 'date_short',
  SERVICE_SHORT = 'service_short',
  SERVICE_NAME = 'service_name',
  VENDOR_FIRST = 'vendor_first',
  USER_FIRST = 'user_first',
  PROVIDER_COMPANY = 'provider_company',
  PROVIDER_NAME = 'provider_name',
  RANGE_START = 'range_start',
  RANGE_END = 'range_end',
}

export enum FieldType {
  SINGLE = 'single',
  MULTI = 'multi',
  ADDRESS = 'address',
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  DATE = 'date',
  SELECT = 'select',
  REPEAT = 'repeat',
  ASSETS = 'assets',
  REQUEST_DATE = 'request_date',
}

export enum RuleAdjustment {
  OVERRIDE_TOTAL = 'override_total',
  OVERRIDE_BASE = 'override_base',
  ADJUST_FLAT = 'adjust_flat',
  ADJUST_QUANTITY = 'adjust_quantity',
}

export enum TimeZone {
  US_EASTERN = 'US/Eastern',
  US_CENTRAL = 'US/Central',
  US_MOUNTAIN = 'US/Mountain',
  US_PACIFIC = 'US/Pacific',
}

export enum VariantValueComparator {
  EQUALS = '=',
  NOT_EXISTS = '!*',
  EXISTS = '*',
}

export enum OrderSource {
  BUYER = 'buyer',
  VENDOR = 'vendor',
  IMPORT = 'import',
}

export enum PerformablePricingType {
  INPUT = 'input',
  FLAT = 'flat',
}

export enum InvoiceInterval {
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  BI_MONTHLY = 'BI_MONTHLY',
  BI_WEEKLY = 'BI_WEEKLY',
}

export enum NotificationType {
  APPOINTMENT_SCHEDULED = 'appointment_scheduled',
  APPOINTMENT_CANCELED = 'appointment_canceled',
  APPOINTMENT_MOVED = 'appointment_moved',
  JOB_ASSIGNED = 'job_assigned',
  JOB_ASSIGNED_CONFIRM = 'job_assigned_confirm',
  JOB_RESCHEDULED = 'job_rescheduled',
  JOB_ASSIGNED_REJECT = 'job_assigned_reject',
  JOB_ACCEPTED = 'job_accepted',
  JOB_MOVED = 'job_moved',
  JOB_DELIVERED = 'job_delivered',
  JOB_DELIVERED_SELECT = 'job_delivered_select',
  JOB_DELIVERED_REVIEW = 'job_delivered_review',
  JOB_CHANGE_REQUEST = 'job_change_request',
  JOB_CHANGE_ACCEPT = 'job_change_accept',
  JOB_CHANGE_REJECT = 'job_change_reject',
  JOB_TRANSIT_REMINDER = 'job_transit_reminder',
  JOB_TRANSIT_END = 'job_transit_end',
  JOB_TRANSIT_ENROUTE = 'job_transit_enroute',
  JOB_TRANSIT_ONSITE = 'job_transit_onsite',
  JOB_SUBMITTED = 'job_submitted',
  JOB_READY = 'job_ready',
  JOB_SCHEDULED = 'job_scheduled',
  JOB_UPDATED = 'job_updated',
  JOB_CANCELED = 'job_canceled',
  JOB_HELD = 'job_held',
  JOB_UPLOAD_FAILED = 'job_upload_failed',
  ORDER_RECEIVED = 'order_received',
  ORDER_CONFIRM = 'order_confirm',
  ORDER_CONFIRM_CHARGE = 'order_confirm_charge',
  ORDER_REVIEW = 'order_review',
  ORDER_QUOTE = 'order_quote',
  ORDER_ACCEPTANCE = 'order_acceptance',
  ORDER_CANCELED_BUYER = 'order_canceled_buyer',
  ORDER_CANCELED_VENDOR = 'order_canceled_vendor',
  ORDER_INVOICED = 'order_invoiced',
  ORDER_COMPLETE = 'order_complete',
  ORDER_COMPLETE_INTERNAL = 'order_complete_internal',
  ORDER_COMPLETE_CHARGE = 'order_complete_charge',
  ORDER_SUBMITTED = 'order_submitted',
  DELIVERABLE_ACCEPT = 'deliverable_accept',
  DELIVERABLE_REJECT = 'deliverable_reject',
  MANUAL = 'manual',
}
