import Center from 'client/global/components/tailwind/Center';
import * as React from 'react';
import PromiseButton from 'shared/components/button/PromiseButton';
import { MessageViewProps } from 'shared/components/tailwind/Message/MessageCommon';

export default function Message({
  textClass,
  title,
  children,
  actions,
  icon,
  titleClass,
  button,
  customActions,
  round,
  bgClass,
  center,
  className,
}: MessageViewProps) {
  const content = (
    <div className="flex">
      <div className={`w-5 h-5 ${textClass}`}>{icon}</div>
      <div className="ml-3 flex-1">
        {title && <div className={`${children ? 'mb-2' : ''} text-sm font-medium ${titleClass}`}>{title}</div>}
        {children && <div className="text-sm text-black opacity-70 space-y-2">{children}</div>}
        {actions && (
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
              {actions.map((a) => (
                <PromiseButton style={button} onClick={a.onClick} key={a.label} icon={a.icon} disabled={!!a.disabled}>
                  {a.label}
                </PromiseButton>
              ))}
              {customActions}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className={`${round ? `rounded-md` : ''} message ${bgClass} p-4 ${className}`}>
      {center ? <Center>{content}</Center> : content}
    </div>
  );
}
