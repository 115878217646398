import * as React from 'react';
import PopupView from 'shared/components/tailwind/Popup/PopupView';

export function TooltipContent({ children }: { children: React.ReactNode }) {
  return <div className="rounded-sm bg-black bg-opacity-80 text-white text-sm px-2 py-0.5 mt-1">{children}</div>;
}

export default function TooltipView({ text, children }: { text: string; children: React.ReactNode }) {
  return (
    <PopupView activator={children} hover={0} className="inline-block cursor-default">
      <TooltipContent>{text}</TooltipContent>
    </PopupView>
  );
}
