import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { CombinedError } from '@urql/core';
import { ClientOptions } from '@urql/core/dist/types/client';
import { RequestPolicy } from '@urql/core/dist/types/types';
import { cacheExchange } from '@urql/exchange-graphcache';
import { UpdateResolver } from '@urql/exchange-graphcache/dist/types/types';
import PhotogError from 'common/PhotogError';
import * as React from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useToken } from 'shared/GlobalState';
import introspection from 'shared/introspection.json';
import { createClient, dedupExchange, Exchange, fetchExchange, Provider, useClient, useQuery } from 'urql';
import { filter, merge, pipe, share, skipWhile, subscribe, take, toPromise } from 'wonka';

const resetVendor: UpdateResolver = (result, args, cache) => {
  // TODO: this wipes out all currente vendor data, use a more specific strategy for better performance
  const queries = cache.inspectFields('Query').filter((x) => x.fieldName === 'vendor');

  for (const query of queries) {
    cache.invalidate('Query', query.fieldName, query.arguments);
  }
};

const cacheExchangeInstance = cacheExchange({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: introspection as any,
  keys: {
    Address: () => null,
    JobProperty: () => null,
    LedgerPaging: () => null,
    LatLng: () => null,
    HdHubConfig: () => null,
    OrderInvoiceLine: () => null,
    UserNotification: () => null,
    TimeRange: () => null,
    RelaConfig: () => null,
    Upload: () => null,
    ActionAttachFilesMetadata: () => null,
    DayBusinessHours: () => null,
    Time: () => null,
    JobNotificationMessageActivity: () => null,
    FieldCondition: () => null,
    JobNotificationMessageActivityMetadata: () => null,
    OrderRequested: () => null,
    MicrositeConfig: () => null,
    FieldValue: () => null,
    NotificationActivityMessage: () => null,
    TierInfo: () => null,
    DeliverableUpdateActivity: () => null,
    ConditionOrderSourceMetadata: () => null,
    ConditionOrderBuyer: () => null,
    ConditionBuyerMetadata: () => null,
    ConditionOrderTime: () => null,
    ConditionOrderDayOfWeek: () => null,
    ConditionAppointmentAddress: () => null,
    ConditionBuyerField: () => null,
    ConditionProvider: () => null,
    ConditionProviderMetadata: () => null,
    ConditionAppointmentTime: () => null,
    ConditionAppointmentDayOfWeek: () => null,
    ConditionOrderRequestedDayOfWeek: () => null,
    ConditionOrderRequestedTime: () => null,
    ConditionOrderSource: () => null,
    ActionJobExpenseMetadata: () => null,
    ActionOrderRevenue: () => null,
    ActionFieldShow: () => null,
    ConditionTime: () => null,
    ConditionFieldDate: () => null,
    ConditionFieldSelect: () => null,
    ConditionDayOfWeek: () => null,
    ActionOrderExpense: () => null,
    VendorQuickbooks: () => null,
    ConditionField: () => null,
    ConditionAddress: () => null,
    ActionOrderRevenueMetadata: () => null,
    ActionAssignProviderMetadata: () => null,
    ActionAssignUserMetadata: () => null,
    ActionFieldDisableMetadata: () => null,
    ActionOrderExpenseMetadata: () => null,
    ConditionPerformableMetadata: () => null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Appointment: (a) => (a as any).eventId,
    ActionJobRevenueMetadata: () => null,
    ProviderPerformableProperty: () => null,
    ContactUser: () => null,
    PerformablePropertyCondition: () => null,
    ProviderArea: () => null,
    DistanceResponse: () => null,
    ProviderAreaBoundary: () => null,
    OrderPaymentActivityMetadata: () => null,
    NotificationConfigSms: () => null,
    OrderPaymentActivity: () => null,
    NotificationConfigEmail: () => null,
    VendorReportBuyerPerformableEntry: () => null,
    VendorReportBuyerPerformable: () => null,
    DeliverableUpdateActivityMetadata: () => null,
    WizardPageCondition: () => null,
    ActionFieldShowMetadata: () => null,
    PerformableCost: () => null,
    JobStatusActivityMetadata: () => null,
    OrderStatusActivityMetadata: () => null,
    OrderUpdateActivityMetadata: () => null,
    TodoActivityMetadata: () => null,
    BuyerUpdateActivityMetadata: () => null,
    OrderLineActivityMetadata: () => null,
    JobNotificationActivityMetadata: () => null,
    OrderNotificationActivityMetadata: () => null,
    JobAssignActivityMetadata: () => null,
    JobUpdateActivityMetadata: () => null,
    CrudActivityMetadata: () => null,
    ActivityChange: () => null,
    ZipcodePolygon: () => null,
    OrderMetadata: () => null,
    NotificationConfigSlack: () => null,
    PerformablePropertyValueCondition: () => null,
    Marketing: () => null,
    UserExists: () => null,
    ConditionFieldAddress: () => null,
    File: () => null,
    MarketingMediaLink: () => null,
    MarketingMediaVideo: () => null,
    ServiceVariantProperty: () => null,
    MarketingMediaImage: () => null,
    NotificationWindow: () => null,
    Attachment: () => null,
    JobCancel: () => null,
  },
  updates: {
    Mutation: {
      updateVendor: (result, args, cache) => {
        cache.invalidate({ __typename: 'Vendor', id: args.vendorId as string });
      },
      updateMemberUser: (result, args, cache) => {
        cache.invalidate({ __typename: 'User', id: args.userId as string });
      },
      createTodo: (result, args, cache) => {
        cache.invalidate({ __typename: 'Order', id: args.orderId as string });
      },
      addInvoiceLine: (result, args, cache) => {
        cache.invalidate({ __typename: 'Order', id: args.orderId as string });
      },
      deleteService: (result, args, cache) => {
        cache.invalidate({ __typename: 'Service', id: args.serviceId as string });
      },
      deleteOrder: (result, args, cache) => {
        cache.invalidate({ __typename: 'Order', id: args.orderId as string });
      },
      deleteJob: (result, args, cache) => {
        cache.invalidate({ __typename: 'Job', id: args.jobId as string });
      },
      deleteInvoiceLine: (result, args, cache) => {
        cache.invalidate({ __typename: 'OrderLine', id: args.lineId as string });
      },
      createPayout: resetVendor,
      createVendor: resetVendor,
      createWizard: resetVendor,
      createProvider: resetVendor,
      createDelivery: resetVendor,
      createService: resetVendor,
      createMemberUser: resetVendor,
      createTask: resetVendor,
      createRole: resetVendor,
    },
  },
});

const cacheBypassExchange: Exchange = (input) => {
  const cache = cacheExchangeInstance(input);

  return (operations) => {
    const shared = pipe(operations, share);

    const uncached = pipe(
      shared,
      filter((op) => op.variables?.['skipCache']),
      input.forward
    );

    const cached = pipe(
      shared,
      filter((op) => !op.variables?.['skipCache']),
      cache
    );

    return merge([uncached, cached]);
  };
};

type PendingEntry = {
  error?: Error;
  promise: Promise<unknown>;
  data?: unknown;
  listeners: number;
  complete: boolean;
  stale: boolean;
};

const pending: Record<string, PendingEntry> = {};

export function useQuerySuspense<D, V extends Record<string, unknown>>(
  query: TypedDocumentNode<D, V>,
  variables?: V,
  policy: RequestPolicy | 'network-first' = 'cache-first',
  refresh = false
): D {
  if (policy === 'network-only' && refresh) {
    throw new Error('Cannot refresh with network-only, use network-first instead.');
  }

  const refVariables = useRef(variables);
  const refRefresh = useRef(refresh);
  const refPolicy = useRef(policy);
  const refQuery = useRef(query);

  const variableHash = useMemo(() => JSON.stringify(refVariables.current), []);

  if (
    JSON.stringify(variables) !== variableHash ||
    refresh !== refRefresh.current ||
    policy !== refPolicy.current ||
    query.kind !== refQuery.current.kind ||
    query.definitions !== refQuery.current.definitions
  ) {
    throw new Error('You cannot change the query, variables, policy or refresh mode between renders.');
  }

  // loc && __key are appended to query object after first execution
  const cacheKey = useMemo(
    () =>
      JSON.stringify({
        kind: refQuery.current.kind,
        definitions: refQuery.current.definitions,
        variables: refVariables.current,
      }),
    []
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const client = refPolicy.current === 'network-only' ? useGraphClient() : useClient();

  const refResponse = useRef<D>();

  if (!refResponse.current) {
    if (!pending[cacheKey]) {
      const requestPolicy =
        refPolicy.current === 'network-first'
          ? 'cache-and-network'
          : refPolicy.current === 'network-only'
          ? 'cache-first'
          : refPolicy.current === 'cache-and-network'
          ? 'cache-only'
          : refPolicy.current;

      const execute = client.query(query, refVariables.current, { requestPolicy });

      const entry: PendingEntry = {
        listeners: 0,
        stale: false,
        complete: false,
        promise: pipe(
          execute,
          skipWhile((r) => r.stale === true),
          take(1),
          toPromise
        ).then(async (r) => {
          let error: Error | string = r.error;

          if (!error) {
            if (refPolicy.current === 'cache-and-network') {
              if (r.data === null) {
                r = await client.query(query, refVariables.current, { requestPolicy: 'cache-and-network' }).toPromise();

                error = r.error;
              } else {
                entry.stale = true;
              }
            }
          }

          if (error) {
            entry.error = error;
          } else {
            entry.data = r.data;
          }

          entry.complete = true;
        }),
      };

      pending[cacheKey] = entry;
    }

    if (pending[cacheKey].error) {
      throw pending[cacheKey].error;
    }

    if (!pending[cacheKey].complete) {
      throw pending[cacheKey].promise;
    }

    refResponse.current = pending[cacheKey].data as D;
  }

  const [result, setResult] = useState<D | Error>(refResponse.current);

  if (result instanceof Error) {
    throw result;
  }

  const resultKey = useMemo(() => JSON.stringify(excludeTypeName(result)), [result]);

  useEffect(() => {
    if (!pending[cacheKey]?.stale && !refRefresh.current) {
      return;
    }

    const requestPolicy =
      pending[cacheKey]?.stale && refPolicy.current === 'cache-and-network' ? 'cache-and-network' : 'cache-first';

    if (pending[cacheKey]) {
      pending[cacheKey].stale = false;
    }

    const execute = client.query(refQuery.current, refVariables.current, { requestPolicy });

    const subscription = pipe(
      execute,
      subscribe((r) => {
        if (!refRefresh.current && subscription?.unsubscribe) {
          // make sure cache-and-network & refresh = false does not keep refreshing
          subscription.unsubscribe();
        }

        if (r.error) {
          setResult(r.error);

          return;
        }

        // __typenames are appended after server response and "look" like a new object in cache
        if (resultKey !== JSON.stringify(excludeTypeName(r.data))) {
          // prevent unnecessary renders
          if (pending[cacheKey]) {
            pending[cacheKey].data = r.data;
          }

          setResult(r.data);
        }
      })
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [client, cacheKey, resultKey]);

  useEffect(() => {
    if (!pending[cacheKey]) {
      return;
    }

    pending[cacheKey].listeners += 1;

    return () => {
      pending[cacheKey].listeners -= 1;

      if (pending[cacheKey].listeners == 0) {
        delete pending[cacheKey];
      }
    };
  }, [cacheKey]);

  return result;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function useQueryHook<D, V extends Record<string, unknown>>(
  query: TypedDocumentNode<D, V>,
  variables?: V,
  policy: RequestPolicy | 'no-cache' = 'network-only',
  refresh = true
): D {
  if (!query) {
    throw new Error('Invalid query passed.');
  }

  const [resp] = useQuery<D, V>({
    query,
    variables: { ...variables, skipCache: policy === 'no-cache' } as V,
    requestPolicy: policy === 'no-cache' ? 'network-only' : policy,
    pause: !refresh,
  });

  if (resp.error) {
    throw resp.error;
  }

  if (resp.data === undefined) {
    throw new Error('Was not expecting data to be undefined.');
  }

  return resp.data;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function onError(error: any) {
  if (error instanceof CombinedError) {
    const photogError = error.graphQLErrors.find((e) => PhotogError.isError(e.extensions));

    if (photogError) {
      throw photogError.extensions;
    }
  }

  throw error;
}

export function useQueryPromise<D, V extends Record<string, unknown>>(
  query: TypedDocumentNode<D, V>,
  policy: RequestPolicy | 'no-cache' = 'network-only'
): (variables?: V) => Promise<D> {
  if (!query) {
    throw new Error('Invalid query passed.');
  }

  const client = useClient();

  return useCallback(
    async (variables?: V) => {
      // make sure we don't have any proxies
      const vars = JSON.parse(JSON.stringify(variables || {}));

      // remove __typename if we populate state with data straight from GraphQL
      removeTypename(vars);

      const resp = await client
        .query(query, { ...vars, skipCache: policy === 'no-cache' } as V, {
          requestPolicy: policy === 'no-cache' ? 'network-only' : policy,
        })
        .toPromise();

      if (resp.error) {
        onError(resp.error);
      }

      if (resp.data === undefined) {
        throw new Error('Was not expecting data to be undefined.');
      }

      return resp.data;
    },
    [client, query, policy]
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function excludeTypeName(obj: any) {
  const newObj = {};

  for (const prop in obj) {
    if (prop === '__typename') {
      continue;
    }

    if (typeof obj[prop] === 'object') {
      newObj[prop] = excludeTypeName(obj[prop]);
    } else {
      newObj[prop] = obj[prop];
    }
  }

  return newObj;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeTypename(obj: any) {
  for (const prop in obj) {
    if (prop === '__typename') {
      delete obj[prop];
    } else if (typeof obj[prop] === 'object') {
      removeTypename(obj[prop] as any); // eslint-disable-line @typescript-eslint/no-explicit-any
    }
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function useMutationPromise<D, V extends object>(query: TypedDocumentNode<D, V>): (v?: V) => Promise<D> {
  if (!query) {
    throw new Error('Invalid query passed.');
  }

  const client = useClient();

  return async (variables?: V) => {
    // make sure we don't have any proxies
    const vars = JSON.parse(JSON.stringify(variables || {}));

    // remove __typename if we populate state with data straight from GraphQL
    removeTypename(vars);

    const resp = await client.mutation(query, vars).toPromise();

    if (resp.error) {
      onError(resp.error);
    }

    if (resp.data === undefined) {
      throw new Error('Was not expecting data to be undefined.');
    }

    return resp.data;
  };
}

export function createGraphClient(url: string, token?: string, fetch?: ClientOptions['fetch']) {
  return createClient({
    url: `${url}?token=${token || ''}`,
    suspense: true,
    fetch,
    exchanges: [dedupExchange, cacheBypassExchange, fetchExchange],
  });
}

const UrlContext = createContext(null as string);

export function useGraphClient() {
  const url = useContext(UrlContext);

  return createClient({
    url,
    suspense: true,
    fetch,
    exchanges: [dedupExchange, cacheBypassExchange, fetchExchange],
  });
}

export function UrqlProvider({
  children,
  url,
  fetch,
}: {
  url?: string;
  children: React.ReactNode;
  fetch?: ClientOptions['fetch'];
}) {
  const token = useToken();

  const endpoint = url || 'https://app.photog.tech/graphql';

  const urqlClient = createGraphClient(endpoint, token, fetch);

  return (
    <UrlContext.Provider value={endpoint}>
      <Provider value={urqlClient}>{children}</Provider>
    </UrlContext.Provider>
  );
}
