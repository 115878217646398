import Center from 'client/global/components/tailwind/Center';
import Link from 'client/global/components/tailwind/Link';
import { loaded } from 'client/global/components/tailwind/SpinnerLoader';
import Auth from 'client/portal/Auth';
import JobGallery from 'client/portal/public/JobGallery';
import { extend } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import * as React from 'react';
import { lazy, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, NavLink, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import Message from 'shared/components/tailwind/Message';
import { MessageType } from 'shared/components/tailwind/Message/MessageCommon';
import Config from 'shared/Config';
import { Action, ShopVendorByDomainDocument } from 'shared/generated';
import { useQueryHook } from 'shared/Graph';
import CtrlLeftIcon from 'shared/icons/CtrlLeftIcon';
import { useLogout, useUser } from 'shared/UserState';
import AccountSelector from './global/components/navigation/AccountSelector';

// support day of month with ordinal (eg: 1st)
extend(advancedFormat);
extend(relativeTime);
extend(weekOfYear);
extend(timezone);
extend(dayOfYear);

function Redirector() {
  const navigate = useNavigate();

  const user = useUser();
  const logout = useLogout();
  const [tried, setTried] = useState(false);

  useEffect(() => {
    if (tried) {
      return;
    }

    const userRolesCount = user.members
      .map(({ actions }) => actions)
      .flat()
      .filter((action) => action !== Action.Buyer).length;

    if (userRolesCount === 1) {
      const vendor = user.members.find((m) => m.actions.includes(Action.Vendor));
      const provider = user.members.find((m) => m.actions.includes(Action.Provider));

      if (vendor) {
        navigate(`/ui/vendor/${vendor.id}`, { replace: true });
      } else if (provider) {
        navigate(`/ui/provider/${provider.id}`, { replace: true });
      }

      return;
    }

    if (user.superuser || userRolesCount > 1) {
      navigate(`/ui/account-selector`, { replace: true });

      return;
    }

    // TODO: smell? loading screen will not go away if we do not redirect to a portal
    loaded();

    setTried(true);
  }, [tried, user, navigate]);

  if (tried) {
    return (
      <Center padding>
        <Message
          round
          type={MessageType.ERROR}
          title="Invalid Account"
          actions={[{ label: 'Logout', onClick: () => logout() }]}
        >
          There are no accounts associated with your email: <strong>{user.email}</strong>.
        </Message>
      </Center>
    );
  }

  return <></>;
}

const VendorPortal = lazy(() => import(/* webpackChunkName: "vendor" */ 'client/portal/vendor/VendorPortal'));
const ProviderPortal = lazy(() => import(/* webpackChunkName: "provider" */ 'client/portal/provider/ProviderPortal'));
const Admin = lazy(() => import(/* webpackChunkName: "admin" */ 'client/portal/admin/Admin'));
const BuyerPortal = lazy(() => import(/* webpackChunkName: "admin" */ 'client/portal/buyer/BuyerPortal'));

function Logout() {
  return (
    <span className="loading">
      <Link to="/">Login</Link>
    </span>
  );
}

function AuthGuard() {
  return (
    <Auth markLoaded={false}>
      <Redirector />
    </Auth>
  );
}

function ImpersonateCustomer() {
  const { vendorId } = useParams();

  return (
    <>
      <div>
        <NavLink
          to={`/ui/vendor/${vendorId}`}
          className="flex bg-yellow-200 text-yellow-900 py-1 px-2 items-center text-xs uppercase font-medium"
        >
          <div className="w-3 h-3 mr-2">
            <CtrlLeftIcon />
          </div>
          return to vendor portal
        </NavLink>
      </div>
      <BuyerPortal />
    </>
  );
}

function V2Frame() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (typeof event.data === 'string' && event.data.startsWith('/ui/') && event.source !== window) {
        navigate(event.data);
      }
    };

    window.addEventListener('message', listener, false);

    return () => {
      window.removeEventListener('message', listener);
    };
  });

  top.postMessage(location.pathname, '*');

  return <></>;
}

export function AppRouter() {
  return (
    <>
      <BrowserRouter>
        <V2Frame />
        <Routes>
          <Route path="/ui/buyer/:vendorId/*" element={<ImpersonateCustomer />} />
          <Route path="/ui/vendor/:vendorId/*" element={<VendorPortal />} />
          <Route path="/ui/provider/:providerMemberId/*" element={<ProviderPortal />} />
          <Route path="/ui/admin/*" element={<Admin />} />
          <Route path="/ui/logout" element={<Logout />} />
          <Route
            path="/ui/account-selector"
            element={
              <Auth markLoaded={true}>
                <AccountSelector />
              </Auth>
            }
          />
          <Route path="/ui" element={<AuthGuard />} />
          <Route path="/public/gallery/:jobId" element={<JobGallery />} />
          <Route path="/" element={<Navigate to="/ui" replace />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

function PortalDetector() {
  // allow setting REACT_APP_CLIENT_DOMAIN in .env file for local testing
  const domain = Config.CLIENT_DOMAIN || window.location.hostname;

  const query = useQueryHook(ShopVendorByDomainDocument, { domain }, 'cache-first');

  if (!query.vendorByDomain) {
    return <AppRouter />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/public/gallery/:jobId" element={<JobGallery />} />
        <Route path="*" element={<BuyerPortal vendorId={query.vendorByDomain.id} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default function App() {
  const authenticatedPortals = ['/ui', '/vendor', '/admin', '/provider', '/buyer'];

  if (authenticatedPortals.some((p) => window.location.pathname.includes(p))) {
    // bypass vendor domain detection if user is requesting a specific portal
    return <AppRouter />;
  }

  return <PortalDetector />;
}
