import * as React from 'react';
import { useContext } from 'react';
import InheritTextView, { useInheritTextColorView } from 'shared/utilities/InheritText//InheritTextView';
import { InheritTextViewProps, TextContext } from 'shared/utilities/InheritText/InheritTextViewCommon';

export function useInheritTextColor(fallback: string) {
  return useInheritTextColorView(fallback);
}

export function useInheritTextClasses() {
  const context = useContext(TextContext);

  return context || [];
}

export function InheritText(props: InheritTextViewProps) {
  return <InheritTextView {...props} />;
}
