import { NotificationType, Permission } from 'shared/generated';

export default {
  [`${NotificationType.AppointmentMoved}.name`]: 'Appointment Moved',
  [`${NotificationType.AppointmentMoved}.vendor`]:
    'Sent to the customer when a locked appointment has been moved and accepted on the schedule.',
  [`${NotificationType.AppointmentMoved}.default`]:
    'The appointment for {{service_short}} at {{address_number}} {{address_street}} has been moved to {{date_short}} @ {{time_short}}.',
  [`${NotificationType.AppointmentScheduled}.name`]: 'Appointment Scheduled',
  [`${NotificationType.AppointmentScheduled}.default`]:
    '{{service_short}} has been scheduled for {{time_short}} on {{date_short}} at {{address_number}} {{address_street}}',
  [`${NotificationType.AppointmentScheduled}.vendor`]:
    'Sent to the customer when an appointment has been locked on the schedule.',
  [`${NotificationType.AppointmentCanceled}.name`]: 'Appointment Canceled',
  [`${NotificationType.AppointmentCanceled}.default`]:
    'The appointment for {{service_short}} scheduled on {{date_short}} at {{address_number}} {{address_street}} has been cancelled.',
  [`${NotificationType.AppointmentCanceled}.vendor`]:
    'Sent to the customer when an appointment has been removed from the schedule.',
  [`${NotificationType.OrderQuote}.name`]: 'Order Quote',
  [`${NotificationType.OrderQuote}.vendor`]: 'Sent to the customer when an order is created and sent as a quote.',
  [`${NotificationType.OrderSubmitted}.name`]: 'Order Submitted',
  [`${NotificationType.OrderSubmitted}.default`]:
    'An order for {{address_number}} {{address_street}} was just submitted by the customer: {{order_link}}',
  [`${NotificationType.OrderSubmitted}.vendor`]:
    'Sent to the internal team when a buyer submits an order through their portal.',
  [`${NotificationType.JobAssigned}.name`]: 'Job Assignment (No Acknowledgement)',
  [`${NotificationType.JobAssigned}.default`]:
    'You have been assigned {{service_short}} at {{time_short}} on {{date_short}} in {{address_city}}. More information can be found: {{job_link}}',
  [`${NotificationType.JobAssigned}.vendor`]:
    'Sent to the provider once a job has been assigned them AND they are NOT required to accept or decline.',
  [`${NotificationType.JobAssigned}.default`]:
    'You have been assigned {{service_short}} at {{time_short}} on {{date_short}} in {{address_city}}. More information can be found: {{job_link}}',
  [`${NotificationType.JobAssignedConfirm}.name`]: 'Job Assignment (Acknowledgement)',
  [`${NotificationType.JobAssignedConfirm}.default`]:
    'You have been assigned a {{service_short}} at {{time_short}} on {{date_short}} in {{address_city}}. Please confirm soon: {{job_link}}',
  [`${NotificationType.JobAssignedConfirm}.vendor`]:
    'Sent to the provider once a job has been assigned them AND they ARE required to accept or decline.',
  [`${NotificationType.JobAssignedReject}.name`]: 'Job Assignment (Rejection)',
  [`${NotificationType.JobAssignedReject}.default`]:
    '{{user_first}} has declined {{service_short}} for job #{{job_id}} at {{time_short}} on {{date_short}}. Please reschedule: {{job_link}}',
  [`${NotificationType.JobAssignedReject}.vendor`]:
    'An internal notification if the provider declines the assigned job.',
  [`${NotificationType.JobCanceled}.name`]: 'Job Canceled',
  [`${NotificationType.JobCanceled}.default`]:
    'The {{service_short}} job for {{address_number}} {{address_street}} has been cancelled.',
  [`${NotificationType.JobCanceled}.vendor`]:
    'Sent to the provider once a job has been assigned and then later canceled or unscheduled.',
  [`${NotificationType.JobHeld}.name`]: 'Job Held',
  [`${NotificationType.JobHeld}.default`]: '{{service_short}} for {{address_number}} {{address_street}} is on hold.',
  [`${NotificationType.JobHeld}.vendor`]:
    'Sent to the provider to indicate a job has been placed on hold and they should not continue work.',
  [`${NotificationType.JobReady}.name`]: 'Job Ready',
  [`${NotificationType.JobReady}.default`]: 'Work is ready to begin on job #{{job_id}}: {{job_link}}',
  [`${NotificationType.JobReady}.vendor`]: 'Sent to the provider once a job is ready to be worked on.',
  [`${NotificationType.JobDelivered}.name`]: 'Job Delivered (Complete)',
  [`${NotificationType.JobDelivered}.default`]:
    'Assets for {{address_number}} {{address_street}} are now available: {{order_link}}',
  [`${NotificationType.JobDelivered}.vendor`]:
    'Sent to the buyer once deliverables have been submitted to the job and accepted by vendor.',
  [`${NotificationType.JobDeliveredSelect}.name`]: 'Job Delivered (Selection)',
  [`${NotificationType.JobDeliveredSelect}.default`]:
    'Assets for {{address_number}} {{address_street}} must be reviewed before work can continue: {{order_link}}',
  [`${NotificationType.JobDeliveredSelect}.vendor`]:
    'Sent to the buyer once deliverables have been submitted for further selection.',
  [`${NotificationType.JobDeliveredReview}.name`]: 'Job Delivered (Review)',
  [`${NotificationType.JobDeliveredReview}.default`]:
    'Assets for {{address_number}} {{address_street}} are available for review: {{order_link}}',
  [`${NotificationType.JobDeliveredReview}.vendor`]:
    'Sent to the buyer to review deliverables on a job before it can be marked complete.',
  [`${NotificationType.JobAccepted}.name`]: 'Job Accepted',
  [`${NotificationType.JobAccepted}.default`]:
    'The customer has accepted delivery of {{service_short}} for {{address_number}} {{address_street}}.',
  [`${NotificationType.JobAccepted}.vendor`]:
    'The provider has accepted the assigned job (when acknowledgement mode is turned on).',
  [`${NotificationType.JobMoved}.name`]: 'Job Moved (By Provider)',
  [`${NotificationType.JobMoved}.default`]:
    'Provider has moved the appointment for {{address_number}} {{address_street}}: {{order_link}}',
  [`${NotificationType.JobMoved}.vendor`]:
    'The provider has accepted the assigned job but moved it to a different date/time.',
  [`${NotificationType.JobScheduled}.name`]: 'Job Scheduled',
  [`${NotificationType.JobScheduled}.vendor`]:
    'Sent to the buyer once the job has been scheduled and acknowledged by the provider.',
  [`${NotificationType.JobRescheduled}.name`]: 'Job Rescheduled',
  [`${NotificationType.JobRescheduled}.default`]:
    'Job #{{job_id}} at {{address_number}} {{address_street}} has been rescheduled, please review: {{job_link}}',
  [`${NotificationType.JobRescheduled}.vendor`]:
    'Sent to the buyer if a scheduled job has changed since the last notification.',
  [`${NotificationType.JobTransitEnroute}.name`]: 'Job Transit (En Route)',
  [`${NotificationType.JobTransitEnroute}.default`]:
    '{{user_first}} is on the way to {{address_number}} {{address_street}}, we will notify you once they arrive.',
  [`${NotificationType.JobTransitEnroute}.vendor`]:
    'Sent to the buyer once the provider has indicated they are on the way to the job site.',
  [`${NotificationType.JobTransitOnsite}.name`]: 'Job Transit (On Site)',
  [`${NotificationType.JobTransitOnsite}.default`]: '{{user_first}} has arrived.',
  [`${NotificationType.JobTransitOnsite}.vendor`]:
    'Sent to the buyer once the provider has indicated they have arrived to the job site.',
  [`${NotificationType.JobTransitEnd}.name`]: 'Job Transit (Leave Site)',
  [`${NotificationType.JobTransitEnd}.default`]: '{{user_first}} has completed work and is now leaving.',
  [`${NotificationType.JobTransitEnd}.vendor`]:
    'Sent to the buyer once the provider has indicated they have left the job site.',
  [`${NotificationType.JobTransitReminder}.name`]: 'Job Transit (Reminder)',
  [`${NotificationType.JobTransitReminder}.default`]:
    'Reminder, a team member will be heading to {{address_number}} {{address_street}} for your {{time_short}} appointment.',
  [`${NotificationType.JobTransitReminder}.vendor`]:
    'Sent to the buyer as a reminder that a provider will come to the service address on the scheduled date.',
  [`${NotificationType.JobUpdated}.name`]: 'Job Updated',
  [`${NotificationType.JobUpdated}.default`]:
    'Details about job #{{job_id}} at {{address_number}} {{address_street}} have changed: {{job_link}}',
  [`${NotificationType.JobUpdated}.vendor`]:
    'Sent to the provider to ensure they are aware of the latest changes to the job.',
  [`${NotificationType.JobChangeRequest}.name`]: 'Job Change (Request)',
  [`${NotificationType.JobChangeRequest}.default`]:
    '{{user_first}} has requested changes to job #{{job_id}}: {{job_link}}',
  [`${NotificationType.JobChangeRequest}.vendor`]:
    'An internal message indicating the provider wants to make changes to a job.',
  [`${NotificationType.JobChangeAccept}.name`]: 'Job Change (Accept)',
  [`${NotificationType.JobChangeAccept}.default`]:
    '{{user_first}} has accepted your changes to job #{{job_id}}: {{job_link}}',
  [`${NotificationType.JobChangeAccept}.vendor`]:
    'Sent to the provider once their job change request has been approved.',
  [`${NotificationType.JobChangeReject}.name`]: 'Job Change (Reject)',
  [`${NotificationType.JobChangeReject}.default`]:
    '{{user_first}} has rejected your changes to job #{{job_id}}: {{job_link}}',

  [`${NotificationType.JobChangeReject}.vendor`]:
    'Sent to the provider once their job change request has been rejected.',
  [`${NotificationType.JobSubmitted}.name`]: 'Job Submitted',
  [`${NotificationType.JobSubmitted}.default`]:
    '{{provider_company}} has submitted their deliverables for review: {{job_link}}',
  [`${NotificationType.JobSubmitted}.vendor`]:
    'An internal message indicating the provider has submitted a job as completed (with deliverables if applicable).',
  [`${NotificationType.DeliverableAccept}.name`]: 'Deliverable Accept',
  [`${NotificationType.DeliverableAccept}.default`]:
    '{{user_first}} has accepted one deliverable for job #{{job_id}}: {{job_link}}',
  [`${NotificationType.DeliverableAccept}.vendor`]: 'Sent to the provider when a deliverable has been approved.',
  [`${NotificationType.DeliverableReject}.name`]: 'Deliverable Reject',
  [`${NotificationType.DeliverableReject}.default`]:
    '{{user_first}} has rejected one deliverable for job #{{job_id}}: {{job_link}}',
  [`${NotificationType.DeliverableReject}.vendor`]: 'Sent to the provider when a deliverable has been rejected.',
  [`${NotificationType.OrderReceived}.name`]: 'Order Received',
  [`${NotificationType.OrderReceived}.vendor`]:
    'Sent to buyer as soon as they submit the order, but before it has been confirm internally.',
  [`${NotificationType.OrderReceived}.default`]:
    'Your order for {{address_number}} {{address_street}} has been received.',
  [`${NotificationType.OrderConfirm}.name`]: 'Order Confirmed (Uncharged)',
  [`${NotificationType.OrderConfirm}.default`]:
    'Your order for {{address_number}} {{address_street}} has been confirmed.',
  [`${NotificationType.OrderConfirm}.vendor`]:
    'Sent to the buyer once their order has been confirmed and no charges were processed (post-pay).',
  [`${NotificationType.OrderConfirmCharge}.name`]: 'Order Confirmed (Charged)',
  [`${NotificationType.OrderConfirmCharge}.default`]:
    'Your order for {{address_number}} {{address_street}} has been confirmed. A total of {{amount}} has been charged to the payment method(s) provided.',
  [`${NotificationType.OrderConfirmCharge}.vendor`]:
    'Sent to the buyer once their order has been confirmed and they were charged (pre-pay).',
  [`${NotificationType.OrderAcceptance}.name`]: 'Order Acceptance',
  [`${NotificationType.OrderAcceptance}.default`]:
    'Order #{{order_id}} has been accepted by {{buyer_company}}: {{order_link}}',
  [`${NotificationType.OrderAcceptance}.vendor`]:
    'Sent to the buyer to confirm the services provided and approve it for completion.',
  [`${NotificationType.OrderCanceledBuyer}.name`]: 'Order Cancel',
  [`${NotificationType.OrderCanceledBuyer}.default`]:
    'The order for {{address_number}} {{address_street}} has been canceled, we apologize for any inconvenience.',
  [`${NotificationType.OrderCanceledBuyer}.vendor`]:
    'Sent to the buyer when an entire confirmed order has been canceled.',
  [`${NotificationType.OrderCanceledVendor}.name`]: 'Order Cancel',
  [`${NotificationType.OrderCanceledVendor}.default`]:
    'The order for {{address_number}} {{address_street}} has been canceled.',
  [`${NotificationType.OrderCanceledVendor}.vendor`]: 'An internal message once a confirmed order has been cancelled.',
  [`${NotificationType.OrderInvoiced}.name`]: 'Order Invoiced',
  [`${NotificationType.OrderInvoiced}.vendor`]:
    'Sent to the buyer once the order is ready for payment and no payment method is assigned to the order.',
  [`${NotificationType.OrderInvoiced}.default`]:
    'Thank you for choosing us! You will find your final invoice for the order attached to this email.',
  [`${NotificationType.OrderCompleteInternal}.name`]: 'Order Complete',
  [`${NotificationType.OrderCompleteInternal}.default`]:
    'The order {{address_number}} {{address_street}} has been completed: {{order_link}}',
  [`${NotificationType.OrderCompleteInternal}.vendor`]: 'Sent to internal team once an order has been completed',
  [`${NotificationType.OrderComplete}.name`]: 'Order Complete (Uncharged)',
  [`${NotificationType.OrderComplete}.default`]:
    'Your order for {{address_number}} {{address_street}} has been completed. Please see the attachment for your final invoice.',
  [`${NotificationType.OrderComplete}.vendor`]:
    'Sent to the buyer once the order has been completed AND they were not charged.',
  [`${NotificationType.OrderCompleteCharge}.name`]: 'Order Complete (Charged)',
  [`${NotificationType.OrderCompleteCharge}.default`]:
    'Your order for {{address_number}} {{address_street}} has been completed. An invoice for your payment should appear in your inbox: {{order_link}}',
  [`${NotificationType.OrderCompleteCharge}.vendor`]:
    'Sent to the buyer once the order has been completed AND they were charged.',
  [`${NotificationType.OrderReview}.name`]: 'Order Review',
  [`${NotificationType.OrderReview}.default`]:
    'Order #{{order_id}} has been completed by all service providers, please review before it can marked complete: {{order_link}}',
  [`${NotificationType.OrderReview}.vendor`]:
    'An internal message indicating that all jobs have been completed and needs review.',

  'inputType.name': 'Input Type',
  'outputType.name': 'Output Type',
  'expense.name': 'Expense',
  'showOnReschedule.name': 'Show on Reschedule',
  'showOnReschedule.desc': 'Allow scheduler to make changes to an order field when moving jobs on the calendar.',
  'expense.desc': 'The amount you pay a provider if they are assigned this job.',
  'notification.override.name': 'Override',
  'notification.override.desc':
    'This notification type has been enabled at the global level and cannot be turned off. However, you may choose to use a different message when override is enabled.',
  'notification.message.name': 'Message',
  'notification.global.name': 'Global',
  'notification.global.desc':
    'When enabled, all services and tasks will be forced to send out this notification, but may customize it.',
  'notification.subject.name': 'Subject',
  'notification.channel.name': 'Channel',
  'name.name': 'Name',
  'fieldType.name': 'Field Type',
  'roles.type.name': 'Role Type',
  'roles.calendar.name': 'Show on Calendar',
  'roles.calendar.desc':
    'When enabled, a user assigned this role will display on calendar events they are associated with.',
  'roles.required.name': 'Required',
  'roles.required.desc': 'When enabled, it forces this role to be defined when modifying a member in the above scope.',
  'apiName.name': 'API Name',
  'micrositeDefaultType.name': 'Microsite Default Type',
  'micrositeType.name': 'Microsite Type',
  'rela.name': 'Rela Microsite Field',
  'estated.name': 'EStated Field',
  'hdPhotoHub.name': 'HDPhotoHub Microsite Field',
  'hdPhotoHubApiKey.name': 'HDPhotoHub API Key',
  'hdPhotoHubUrl.name': 'HDPhotoHub URL',
  'timezone.name': 'Timezone',
  'inlineScheduling.name': 'Inline Scheduling',
  'inlineScheduling.desc':
    'Instead of scheduling work after orders have been created, you can choose to book providers and times before the order is submitted.',
  'collapseTimeSlots.name': 'Collapse time slots',
  'collapseTimeSlots.desc': 'Auto zooms to a 15 or 10 minute slot size when short appointments are on the calendar.',
  'requestTimesError.name': 'Request times conflict error',
  'requestTimesError.desc': 'Make order schedule conflict with request times an error vs warning.',
  'buyerReminderMinutes.name': 'Buyer Reminder',
  'buyerReminderMinutes.desc':
    'The number of minutes prior to a job in which to remind a buyer of a provider coming out.',
  'buyerDailyReminder.name': 'Daily Reminder Time',
  'buyerDailyReminder.desc':
    'At this time each day, a reminder notification will go out for any appointments scheduled.',
  'reviewOrder.name': 'Internal Order Review',
  'reviewOrder.desc':
    'After all jobs on an order have been completed, the order will go through a final review by your internal team before it is considered complete.',
  'showCalendarTitles.name': 'Show Calendar Titles',
  'applicationFee.name': 'Application Fee',
  [`${Permission.BuyerCost}.name`]: '(Customer) View Order/Service Costs',
  [`${Permission.BuyerPayment}.name`]: '(Customer) Manage Payment Methods',
  [`${Permission.BuyerInvoice}.name`]: '(Customer) Sent Invoices',
  [`${Permission.ViewExpenses}.name`]: '(Internal) View expenses',
  [`${Permission.AccessAccounting}.name`]: '(Internal) Access accounting',
  [`${Permission.AccessReporting}.name`]: '(Internal) Access reporting',
  [`${Permission.AccessSettings}.name`]: '(Internal) Access settings',
};
