import { loaded } from 'client/global/components/tailwind/SpinnerLoader';
import { redirectToLogin } from 'client/utils/utils';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Action } from 'shared/generated';
import { useUser } from 'shared/UserState';

export default function AccountSelector() {
  const user = useUser();

  loaded();

  if (!user) {
    redirectToLogin();

    return <></>;
  }

  const getDashboardUrl = (action: Action, id: string) => {
    switch (action) {
      case Action.Vendor:
        return `/ui/vendor/${id}`;
      case Action.Provider:
        return `/ui/provider/${id}`;
    }
  };

  return (
    <div className="flex-1 w-full h-full flex flex-col items-center justify-center bg-theme-body">
      <p className="font-semibold text-gray-900 pt-4 mb-2 text-center">Choose an account:</p>
      <div className="bg-content border-t-8 border-theme-primary p shadow flex flex-col items-center round">
        <div className="w-80" />
        <ul role="list" className="-my-5 divide-y divide-gray-200">
          {user.superuser && (
            <li className="py-4">
              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">{user.name}</p>
                  <p className="text-sm text-gray-500 truncate">Super Admin</p>
                </div>
                <div>
                  <NavLink
                    to={'/ui/admin'}
                    className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                  >
                    Continue
                  </NavLink>
                </div>
              </div>
            </li>
          )}
          {user.members.map((member) => {
            return member.actions
              .filter((action) => action !== Action.Buyer)
              .map((action) => (
                <li className="py-4" key={`${member.id}-${action}`}>
                  <div className="flex  space-x-4">
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate">{member.company}</p>
                      <p className="text-sm text-gray-500 truncate capitalize">{action.toLowerCase()}</p>
                    </div>
                    <div>
                      <NavLink
                        to={getDashboardUrl(action, member.id)}
                        className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Continue
                      </NavLink>
                    </div>
                  </div>
                </li>
              ));
          })}
        </ul>
      </div>
    </div>
  );
}
