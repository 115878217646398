import { NotificationType } from '../enums';
import { AttachmentDo } from '../model';

export interface ActionJob {
  performableId?: string;
  fieldId?: string;
  fieldOptionId?: string;
}

export enum ActionAmountType {
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
}

export enum ExpenseSplitType {
  JOB = 'job',
  EVENT = 'event',
}

export interface ActionOrderRevenue {
  amount: string;
  amountType: ActionAmountType;
  line?: string;
}

export interface ActionOrderExpense {
  amount: string;
  split?: ExpenseSplitType;
  line?: string;
}

export interface ActionJobRevenue extends ActionJob {
  amount: string;
  amountType: ActionAmountType;
  line?: string;
}

export interface ActionJobExpense extends ActionJob {
  amount: string;
  amountType: ActionAmountType;
  line?: string;
  providerMemberId?: string;
}

export interface ActionJobOnsite extends ActionJob {
  minutes: number;
}

export interface ActionJobAssign extends ActionJob {
  providerId?: string;
  userId?: string;
}

export interface ActionJobAcknowledge extends ActionJob {
  acknowledge: boolean;
}

export interface ActionField {
  fieldId: string;
}

export interface ActionFieldShow extends ActionField {
  visible: boolean;
}

export interface ActionFieldRequire extends ActionField {
  required: boolean;
}

export interface ActionFieldDisable extends ActionField {
  disabled: boolean;
}

export interface ActionPageShow {
  wizardPageId: string;
  required: boolean;
}

export interface ActionOrderLine {
  description: string;
  revenue: string;
}

export interface ActionAssignProvider {
  providerId: string;
  performableId?: string;
}

export interface ActionAssignUser {
  userId: string;
  performableId?: string;
}

export interface ActionAttachFiles {
  notificationType: NotificationType;
  attachments: AttachmentDo[];
}

export interface BaseAction<T extends ActionType, M> {
  id: string;
  metadata: M;
  type: T;
}

export enum ActionType {
  ORDER_REVENUE = 'ORDER_REVENUE',
  ORDER_EXPENSE = 'ORDER_EXPENSE',
  JOB_REVENUE = 'JOB_REVENUE',
  JOB_EXPENSE = 'JOB_EXPENSE',
  JOB_ONSITE = 'JOB_ONSITE',
  JOB_ASSIGN = 'JOB_ASSIGN',
  JOB_ACKNOWLEDGE = 'JOB_ACKNOWLEDGE',
  FIELD_SHOW = 'FIELD_SHOW',
  FIELD_REQUIRE = 'FIELD_REQUIRE',
  FIELD_DISABLE = 'FIELD_DISABLE',
  PAGE_SHOW = 'PAGE_SHOW',
  ASSIGN_PROVIDER = 'ASSIGN_PROVIDER',
  ASSIGN_USER = 'ASSIGN_USER',
  ATTACH_FILES = 'ATTACH_FILES',
}

export type Action =
  | BaseAction<ActionType.JOB_REVENUE, ActionJobRevenue>
  | BaseAction<ActionType.JOB_EXPENSE, ActionJobExpense>
  | BaseAction<ActionType.JOB_ONSITE, ActionJobOnsite>
  | BaseAction<ActionType.JOB_ASSIGN, ActionJobAssign>
  | BaseAction<ActionType.JOB_ACKNOWLEDGE, ActionJobAcknowledge>
  | BaseAction<ActionType.FIELD_SHOW, ActionFieldShow>
  | BaseAction<ActionType.FIELD_REQUIRE, ActionFieldRequire>
  | BaseAction<ActionType.FIELD_DISABLE, ActionFieldDisable>
  | BaseAction<ActionType.PAGE_SHOW, ActionPageShow>
  | BaseAction<ActionType.ORDER_REVENUE, ActionOrderRevenue>
  | BaseAction<ActionType.ORDER_EXPENSE, ActionOrderExpense>
  | BaseAction<ActionType.ASSIGN_PROVIDER, ActionAssignProvider>
  | BaseAction<ActionType.ASSIGN_USER, ActionAssignUser>
  | BaseAction<ActionType.ATTACH_FILES, ActionAttachFiles>;
