import * as React from 'react';

export default function Center({
  children,
  padding,
  small,
  className,
}: {
  className?: string;
  small?: boolean;
  children: React.ReactNode;
  padding?: boolean;
}) {
  return (
    <div
      className={`${small ? 'max-w-screen-md' : 'max-w-screen-xl'} px-6 flex-1 flex flex-col ${padding ? 'py-6' : ''}`}
    >
      {className ? <div className={className}>{children}</div> : children}
    </div>
  );
}
