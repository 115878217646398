/* eslint-disable @typescript-eslint/no-explicit-any */
import { Path, PluginCallbacks, State, StateValueAtRoot } from '@hookstate/core';
import { Initial } from '@hookstate/initial';
import { Touched } from '@hookstate/touched/dist';

const ValidationId = Symbol('Validation');

type ValidateFn<T> = (value: T, ...depends: State<any>[]) => boolean;

interface SingleValidator<T> {
  required(message?: string): void;

  validate(validator: ValidateFn<T>, message?: string): void;
}

export type DetectValidator<T> = T extends string | number | boolean
  ? SingleValidator<T>
  : T extends any[]
  ? ArrayValidator<T>
  : ObjectValidator<T>;

type FieldValidator<T> = {
  [Key in keyof T]: DetectValidator<T[Key]>;
};

type ObjectValidator<T> = SingleValidator<T> &
  FieldValidator<T> & {
    when(fn: (value: State<T>) => boolean): ObjectValidator<T>;
  };

export type ArrayValidator<T extends any[]> = SingleValidator<T> &
  FieldValidator<T[0]> & {
    when<D extends unknown[]>(fn: (item: State<T[0]>, all: State<T>) => boolean): ArrayValidator<T>;
  };

interface Condition {
  fn: (value: any) => boolean;
  path: Path;
}

interface Validator {
  fn: ValidateFn<any>;
  path: Path;
  message?: string;
  required?: boolean;
  conditions: Condition[];
}

class ValidatorInstance<T> {
  validators: Validator[] = [];

  constructor(public root: State<StateValueAtRoot>, private immediate: boolean) {}

  isRequired(state: State<T>) {
    const required = this.validators.filter((v) => v.required);

    return required.some((validator) => {
      const target = this.findChild(state.path, validator.path, state);

      if (!target) {
        return false;
      }

      return validator.conditions.every((condition) => condition.fn(target));
    });
  }

  valid(state: State<T>, force = false) {
    return this.errors(state, force).length === 0;
  }

  errors(state: State<T>, force = false) {
    const errors: string[] = [];

    for (const validator of this.validators) {
      const targets = this.findChildren(state, state.path, validator.path);

      for (const target of targets) {
        if (!this.immediate && !force && !Touched(target).touched()) {
          // if immediate is disabled, wait until user interacts with field before validating
          continue;
        }

        const match = validator.conditions.every((condition) => condition.fn(target));

        if (!match) {
          continue;
        }

        if (!validator.fn(target.get())) {
          errors.push(validator.message || 'This field is not valid.');
        }
      }
    }

    return errors;
  }

  public findChildren(root: State<any>, current: Path, requested: Path): State<any>[] {
    if (Array.isArray(root)) {
      if (root.length === 1 && root.path.length === 1 && root[0] === root.path[0]) {
        // if any array is required
        return [root];
      }

      let items: State<any>[] = [];

      root.forEach((item) => {
        items = [...items, ...this.findChildren(item, item.path, requested)];
      });

      return items;
    }

    const statePaths = current.slice(0);
    const requestedPaths = requested.slice(0);

    while (requestedPaths.length) {
      if (!statePaths.length) {
        const requestedPath = requestedPaths.shift();

        if (root.keys === undefined) {
          return [];
        }

        const nested = root.nested(requestedPath);

        if (!requestedPaths.length) {
          return [nested];
        }

        return this.findChildren(nested, nested.path, requested);
      }

      const statePath = statePaths.shift();

      const stateIsNumber = typeof statePath === 'number';
      const requestedIsNumber = typeof requestedPaths[0] === 'number';
      const requestedIsString = typeof requestedPaths[0] === 'string';

      const comparePaths =
        (requestedIsString && (!stateIsNumber || requestedPaths.length === 1)) || (stateIsNumber && requestedIsNumber);

      if (comparePaths) {
        if (statePath === requestedPaths[0]) {
          requestedPaths.shift();
        } else if (!(stateIsNumber && requestedIsString)) {
          return [];
        }
      }
    }

    return statePaths.length === 0 ? [root] : [];
  }

  public findChild(current: Path, requested: Path, root = this.root): State<any> {
    let target = root;

    const statePaths = current.slice(0);
    const requestedPaths = requested.slice(0);
    const rootPaths = root.path.slice(0);

    while (requestedPaths.length) {
      const statePath = statePaths.shift();

      if (statePath === undefined) {
        return null;
      }

      if (rootPaths.length > 0) {
        if (requestedPaths[0] !== statePath) {
          if (statePaths[0] !== requestedPaths[0]) {
            return null;
          }
        } else {
          requestedPaths.shift();
        }

        rootPaths.shift();

        continue;
      }

      target = target.nested(statePath);

      if (!target.ornull) {
        return null;
      }

      if (requestedPaths[0] === statePath) {
        requestedPaths.shift();
      }
    }

    if (typeof statePaths[0] === 'number') {
      return target[statePaths[0]];
    }

    return target;
  }

  public findAncestor(current: Path, requested: Path): State<any> {
    const statePaths = current.slice(0);
    const requestedPaths = requested.slice(0);

    let target = this.root;

    while (requestedPaths.length) {
      target = target[statePaths[0]];

      const comparePaths =
        typeof statePaths[0] === 'string' ||
        (typeof statePaths[0] === 'number' && typeof requestedPaths[0] === 'number');

      if (comparePaths) {
        if (statePaths[0] === requestedPaths[0]) {
          requestedPaths.shift();
          statePaths.shift();

          continue;
        }

        return null;
      }

      statePaths.shift();
    }

    if (statePaths.length === 1 && typeof statePaths[0] === 'number') {
      return target[statePaths[0]];
    }

    return target;
  }
}

function buildProxy(instance: ValidatorInstance<any>, path: Path, state: State<any>, conditions: Condition[]): any {
  return new Proxy(
    {},
    {
      apply(t: (fieldValidator: ValidateFn<any>) => void, thisArg: any, argArray?: any): any {
        t.apply(thisArg, argArray);
      },
      get(_: any, nestedProp: PropertyKey) {
        if (typeof nestedProp === 'symbol') {
          throw new Error('Symbols are not supported.');
        }

        const cleanPath = path.filter((p) => typeof p !== 'number');

        if (nestedProp === 'path') {
          return cleanPath;
        }

        if (nestedProp === 'whenType') {
          return (key: any, value: any) =>
            buildProxy(instance, path, state, [
              ...conditions,
              {
                fn: (s) => s[key].get() === value,
                path,
              },
            ]);
        }

        if (nestedProp === 'when') {
          return (when: ValidateFn<any>) => {
            return buildProxy(instance, path, state, [
              ...conditions,
              {
                fn: (value) => {
                  const target = instance.findChild(value.path, path);

                  if (when.length === 2) {
                    const parent = instance.findAncestor(value.path, path);

                    return when(target, parent);
                  }

                  return when(target);
                },
                path,
              },
            ]);
          };
        }

        if (nestedProp === 'required') {
          return (message?: string) => {
            instance.validators.push({
              fn: (value) => (Array.isArray(value) ? value.length > 0 : typeof value === 'number' ? true : !!value),
              path,
              required: true,
              message: message || 'This field is required',
              conditions,
            });
          };
        }

        if (nestedProp === 'validate') {
          return (nestedValidator: ValidateFn<any>, message?: string) => {
            instance.validators.push({
              fn: nestedValidator,
              path,
              message,
              conditions,
            });
          };
        }

        let nestedPropConverted: PropertyKey = typeof nestedProp === 'string' ? parseInt(nestedProp, 10) : nestedProp;

        if (isNaN(nestedPropConverted)) {
          nestedPropConverted = nestedProp;
        }

        return buildProxy(instance, [...path, nestedPropConverted], state, conditions.slice(0));
      },
    }
  );
}

type PathFields<S> = (keyof S)[] | ((s: State<S extends (infer T)[] ? T : S>) => boolean);

export interface PathValidator<S> {
  valid(force?: boolean, fields?: PathFields<S>): boolean;

  required(): boolean;

  errors(force?: boolean, fields?: PathFields<S>): string[];
}

export function Validation<T>(input: State<T>): PathValidator<T> {
  input.attach(Initial); // required due to .merge()'d arrays

  const [instance] = input.attach(ValidationId);

  if (instance instanceof Error) {
    throw new Error(`Forgot to run ValidationAttach()`);
  }

  if (!(instance instanceof ValidatorInstance)) {
    throw new Error('Expected plugin to be of ValidatorInstance');
  }

  return {
    valid(force = false, fields?: PathFields<T>): boolean {
      /**
       * FIXME: investigate
       *
       * For some reason, when you add a new object to the array and it is initially invalid,
       * once the object is edited to become valid, any component with a .valid() call is never re-rendered.
       *
       * force component to listen for state changes
       *
       * TODO: As a workaround, if you run into this issue, place the form into a SlideBar component.
       */
      try {
        Initial(input).modified();
      } catch (ex) {
        // removing items from array causes exception
      }

      return this.errors(force, fields).length === 0;
    },
    required(): boolean {
      return instance.isRequired(input);
    },
    errors(force = false, fields?: PathFields<T>): string[] {
      if (fields === undefined) {
        return instance.errors(input, force);
      }

      let errors: string[] = [];

      if (typeof fields === 'function') {
        if (Array.isArray(input)) {
          input.forEach((item) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (fields(item)) {
              errors = [...errors, ...instance.errors(item, force)];
            }
          });
        }
      } else {
        for (const field of fields) {
          errors = [...errors, ...instance.errors(input.nested(field), force)];
        }
      }

      return errors;
    },
  };
}

export function ValidationAttach<T>(
  state: State<T>,
  config?: (validator: DetectValidator<T>) => void,
  immediate = false
): DetectValidator<T> {
  state.attach(Touched);

  let api;

  state.attach(() => ({
    id: ValidationId,
    init: (root) => {
      const instance = new ValidatorInstance(root, immediate);

      api = buildProxy(instance, state.path, state, []);

      config && config(api);

      return instance as PluginCallbacks;
    },
  }));

  return api;
}
