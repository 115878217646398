import * as React from 'react';
import ButtonView from 'shared/components/tailwind/Button/ButtonView';
import { ButtonViewProps } from 'shared/components/tailwind/Button/ButtonViewCommon';
import { Validation } from 'shared/utilities/Validation';

export default function Button({ onClick, disabled, ...props }: ButtonViewProps) {
  let disable = disabled;

  if (disabled && typeof disabled !== 'string' && typeof disabled !== 'boolean') {
    disable = !Validation(disabled).valid(true);
  }

  return (
    <ButtonView
      {...props}
      disabled={disable}
      onClick={(e) => {
        if (disable) {
          return;
        }

        if (onClick) {
          onClick(e);
        }
      }}
    />
  );
}
